import * as React from 'react';
import Modal from '@mui/material/Modal';
import { Layout, ModalBox, TextContainer, Image, Heading, Paragraph } from './PopupModalElements';

export default function PopupModal(props) {

  return (
<div>
  
        <Modal 
          open={props.open}
          onClose={props.handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Layout>
           <ModalBox >
             <Image><img src={props.image} alt='insightimage' width='100%' height='100%' /> </Image>
          <TextContainer>
          <Heading>{props.Title}</Heading>
          <Paragraph>{props.Content}</Paragraph>
          
          </TextContainer>
          </ModalBox>
          </Layout>
      </Modal>
      
    </div>
  );
}