import classes from "./Login.module.css";
import BinSevanLogo from '../../../assets/miniLogo.svg'
import * as React from 'react';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import FilledInput from '@mui/material/FilledInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import AuthContext from '../auth-context';

const LoginPage = () => {
  const authCtx = React.useContext(AuthContext);
  const [showPassword, setShowPassword] = React.useState(false);
  const [userName, setuserName] = React.useState("");
  const [password, setpassword] = React.useState("");
  const [validUsername, setvalidUsername] = React.useState(false);
  const [validPass, setvalidPass] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const signInWithEmailAndPasswordHandler = authCtx.signInWithEmailAndPasswordHandler;

  const handleLogin = () => {
    signInWithEmailAndPasswordHandler(userName, password);
  }

  return (
    <div className={classes.loginContainer}>
      <div className={classes.cart}>
        <div className={classes.logoContainer}>
          <img src={BinSevanLogo} alt="BinSevanLogoo" className={classes.logoo} /></div>

        <div className={classes.formContainer}>
          {validUsername && <p className={classes.validation}>Incorrect User Name</p>}
          {validPass && <p className={classes.validation}>Incorrect Password</p>}

          <div style={{
            marginBottom: '30px'
          }}>
            <FormControl className={classes.inputt} variant="filled">
              <InputLabel htmlFor="filled-adornment-password">User Name</InputLabel>
              <FilledInput
                id="userName"
                type='text'
                value={userName}
                onChange={(e) => {
                  setuserName(e.target.value);
                }}
                required
              />
            </FormControl></div>
          <div>
            <FormControl variant="filled" className={classes.inputt}>
              <InputLabel htmlFor="filled-adornment-password">Password</InputLabel>
              <FilledInput
                id="password"
                type={showPassword ? 'text' : 'password'}
                value={password}
                onChange={(e) => {
                  setpassword(e.target.value);
                }}
                required
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl></div>
          <div>
            <Button variant="contained" className={classes.buttonn} onClick={handleLogin} type="submit">Login</Button>
          </div>

        </div>
      </div>
    </div>
  )
}

export default LoginPage




