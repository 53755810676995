import React, { useState } from 'react'
import ContactUs from '../ContactUsSection'
import Footer from '../FooterSection'
import Heading from '../HeadingSection'
import Insights from '../InsightsSection'
import Navbar from '../NavbarSection'
import OurServices from '../OurServicesSection'
import OurTeam from '../OurTeamSection'
import Story from '../StorySection'
import Sidebar from '../SidebarSection'
import { storyobj } from '../StorySection/Data'
import AdminPanel from '../../Admin Panel/Menu/AdminPanel'
import Team from '../../Admin Panel/Menu/Team/Team'
// import Testimonial from '../TestimonialsSection'

const HomePage = () => {

  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
   <>
          <Navbar toggle={toggle}/>
         <Sidebar isOpen={isOpen} toggle={toggle} />
        <Heading /> 
        <Story {...storyobj}/>
        <OurServices />
        <OurTeam/>
        <Insights/>
        {/* <Testimonial/> */}
         <ContactUs/> 
         <Footer /> 
 </>
  )
}

export default HomePage