import styled from 'styled-components'
import { FaTimes } from 'react-icons/fa'
import BG from '../../../assets/services-bg.webp'
import { Link as LinkS } from 'react-scroll';

export const SidebarContainer = styled.div`
    position: fixed;
    z-index: 999;
    width: 100%;
    height: fit-content;
    background: url(${BG});
    display: grid;
    align-items: center;
    top: 0;
    left: 0;
    transition: 0.5s ease-in-out;
    opacity: ${({ isOpen }) => (isOpen ? '100%' : '0')};
    top: ${({ isOpen }) => (isOpen ? '0' : '-100%')};
    
`

export const CloseIcon = styled(FaTimes)` 
    color: #fff;
    margin-left: 60%;
`

export const Icon = styled.div`
    position: absolute;
    top: 1.2rem;
    right: 7%;
    background: transparent;
    font-size: 2rem;
    cursor: pointer;
    outline: none;
`

export const Metaverse = styled.p`
display: flex;
align-items: center;
justify-content: center;
font-size: 1.5rem;
font-weight: 400;
text-decoration: none;
list-style: none;
transition: 0.2s ease-in-out;
text-decoration: none;
color:  #fff;
cursor: pointer;
margin-top: 4%;
`;

export const SidebarWrapper = styled.div`
    color: #fff;
`

export const SidebarMenu = styled.ul`
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(6, 50px);
    text-align: center;
    margin-right: 7%;
    margin-top: 15%;

    @media screen and (max-width: 480px) {
        grid-template-rows: repeat(6, 60px);
    }

`

export const SidebarLink = styled(LinkS)`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    text-decoration: none;
    list-style: none;
    transition: 0.2s ease-in-out;
    text-decoration: none;
    color:  #fff;
    cursor: pointer;

    &:hover{
        transition: 0.2s ease-in-out;
    }


`