import React, { useEffect, useRef, useState } from 'react'
import classes from './StoryModal.module.css';

import TextField from '@mui/material/TextField';
import Card from '../../../Common/Card/Card';
import CreateIcon from "@mui/icons-material/Create";
import AuthContext from "../../../auth-context";

const AddStory = (props) => {  
  const authCtx = React.useContext(AuthContext);
  
  const [id, setid] = useState(authCtx.selectedItem.id);
  const [title, setTitle] = useState(authCtx.selectedItem.title);
  const [description, setDescription] = useState(authCtx.selectedItem.description);


  const addMemberBtn = () => {
    if(!authCtx.editmood){
    const data = {
      key: (authCtx.story.length + 1),
      title: title,
      description: description,

    };
    console.log(data);
    authCtx.addStory(data);
    authCtx.setOpenstoryModal(false);
  }
    if(authCtx.editmood){
      const data = {
        id: id,
        title: title,
        description: description,
  
      };
      console.log(data);
      authCtx.updateStory(id, data);
      authCtx.setOpenstoryModal(false);
    }
  };
  return (
    <div>
      <Card className={classes.card1}>
        <div className={classes.heading}><h3> Story Information </h3></div>
        <div className={classes.inputs_location_container}>

          <div className={classes.info_rows}>
            <TextField
              id="title"
              label="Title*"
              type="search"
              variant="standard"
              value={title}
              onChange={(e) => { setTitle(e.target.value) }}
              // disabled
            // className={classes.input_description}
            />
            <TextField
              id="description"
              label="Description*"
              type="search"
              variant="standard"
              multiline
              rows={4}
              value={description}
              onChange={(e) => { setDescription(e.target.value) }}
            // className={classes.input_description}
            />
            <p className={classes.note}>*Note: If you want to split to new line type \n.</p>
          </div>
        </div>
      </Card>

      <div className={classes.btns}>
        <button className={classes.btncancel} onClick={props.onClose}>
          Cancel
        </button>
        <button className={classes.btnsave} onClick={addMemberBtn} >
          Save
        </button>
      </div>
    </div>
  )
}

export default AddStory

