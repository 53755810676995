import { Navigate, Route, Routes } from 'react-router-dom';
import './App.css';
import HomePage from './components/Front-End/Pages/HomePage';
import AdminPanel from './components/Admin Panel/Menu/AdminPanel';
import LoginPage from './components/Admin Panel/Login Form/LoginPage';
import { useContext, useState } from 'react';
import AuthContext from './components/Admin Panel/auth-context';
import LogIn from './components/Front-End/Metaverse Login/LogIn';
import { ToastContainer } from 'react-toastify';
function App() {
  const authCtx = useContext(AuthContext);

  return (
    <div className="App">
      <Routes>
        <Route path='/' element={<HomePage />} />
        <Route path='/admin' element={!authCtx.user ? <LoginPage /> : <AdminPanel />} />
        <Route path='/metaverse' element={<LogIn/>} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
        style={{color:'red'}}
        />
    </div>
  );
}

export default App;
