import React, { useState } from 'react';
import { CgCloseO } from 'react-icons/cg';

import { AvatarCon, P, HeadingContainer, HeadingImage, AvatarIcon, AvatarDescription, VideoContainer, IconContainer} from './HeadingElements';
import AvatarIconImg from '../../../assets/avatar-icon.png'
import Video from './Video';

const Heading = () => {
  const [showvideo, setShowVideo] = useState(false);

  const handleCloseModal = () => {setShowVideo(false)};
  const handleShowModal = () => {setShowVideo(true)};

  return (
    <>
    <HeadingContainer id='home'>
        <HeadingImage >
          {!showvideo && <AvatarCon onClick={handleShowModal}>
            <AvatarDescription >
              <p>“The safety of people shall be the highest law.”</p>
            </AvatarDescription>
            <P>- Marcus Tullius Cicero</P>
            <AvatarIcon >
              <img src={AvatarIconImg} alt='AvatarIconImg' />
            </AvatarIcon>
          </AvatarCon>}
          {showvideo && <VideoContainer onEnded={handleCloseModal} ><IconContainer><CgCloseO onClick={handleCloseModal} size="2.1rem" /></IconContainer> <Video /></VideoContainer>}
        </HeadingImage>
        
    </HeadingContainer>
    <div id='story'/>
    </>
  )
}

export default Heading;