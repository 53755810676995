import React, { useState } from "react";
import "./OurService.css";
import PopupModal from "./PopupModal";
import { Link } from "react-scroll";
import civil from '../../../assets/Services/civil-law.webp';
import maritime from '../../../assets/Services/maritime.webp';
import commercial from '../../../assets/Services/commercial.webp';
import construction from '../../../assets/Services/construction.webp';
import mergers from '../../../assets/Services/mergers.webp';
import real from '../../../assets/Services/real.webp';
import banking from '../../../assets/Services/banking.webp';
import corporate from '../../../assets/Services/corporate.webp';
import criminal from '../../../assets/Services/criminal.webp';
import employment from '../../../assets/Services/employment.webp';
import oil from '../../../assets/Services/oil.webp';
import restructing from '../../../assets/Services/restructing.webp';
import tech from '../../../assets/Services/tech.webp';
import family from '../../../assets/Services/family.webp';
import domes from '../../../assets/Services/domestic.webp';
import dataProtection from '../../../assets/Services/DATA-PROTECTION.webp';
import metaverse from '../../../assets/Services/METAVERSE-TECHNOLOGY.webp';
import blockchain from '../../../assets/Services/BLOCKCHAIN.webp';
import AuthContext from "../../Admin Panel/auth-context";




const OurServices = () => {
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const [Title, setTitle] = useState("");
  const [Content, setContent] = useState("");
  const [images, setImage] = useState("");
  const [showmore, setIsshow] = useState(true);

  const authCtx = React.useContext(AuthContext);




  const data = [
    // {
    //   key: 1,
    //   title: "REAL ESTATE",
    //   content:
    //     "With a proven track record of excellence in the real estate business, an award winning team advises....",
    //   full_content:
    //     "With a proven track record of excellence in the real estate business, an award-winning team advises on the entire real estate life span, from real estate acquisition, corporate structuring, leasing, litigation, and arbitration to investor relationships and off-plan sales. Our clients are international developers, retailers, landlords, and tenants, as well as commercial and governmental entities, for which we act in complex legal structures for due diligence or litigation in multiple areas: leisure, tourism, industry and infrastructure, commercial, residential, and strata matters, with a specialized rent committee advice. Successful litigation and representation for key market players like Triplante International FZC, Indigo Properties, or Schon Properties add value to our considerable experience, translated into projects of a combined value exceeding AED 40 billion.",
    //   image: real,
    // },
    // {
    //   key: 2,
    //   title: "EMPLOYMENT",
    //   content:
    //     "Bin Sevan Advocates & Legal Consultants has a well established practice in delivering legal advice....",
    //   full_content:
    //     "Bin Sevan Advocates & Legal Consultants has a well-established practice in delivering legal advice to both employees and employers, under the federal or free zones employment legislation and practices. Our strong legal knowledge in the field comprises immigration and residency, compliance, employees’ benefits, gratuity, end-of-service settlements, incentives, and employment contract drafting and management.",
    //   image: employment,
    // },
    // {
    //   key: 3,
    //   title: "MERGERS & ACQUISITIONS",
    //   content:
    //     "Our practice is a regional landmark in relation to best strategies for mergers and acquisitions....",
    //   full_content:
    //     "Our practice is a regional landmark in relation to best strategies for mergers and acquisitions. High-profile transactions and various aspects of mergers and acquisitions define our focus on our client’ commercial interests in structuring, negotiating, and enforcing transactions. Due diligence, reporting, and in-depth knowledge of drafting LOIs, MOAs, share transfers, memoranda, and writs of enforcement are our core capabilities in delivering profitable and lasting results to our clients.",
    //   image: mergers,
    // },
    // {
    //   key: 4,
    //   title: "MARITIME, TRANSPORT & TRADE",
    //   content:
    //     "With an innate legal sense and knowledge of the field technicalities, Bin Sevan Advocates & Legal....",
    //   full_content:
    //     "With an innate legal sense and knowledge of the field technicalities, Bin Sevan Advocates & Legal Consultants constantly advises clients on charter parties, bills of lading, international commodities, sales and purchase, transfers, insurance and collision, shipbuilding and repair, and much more.",
    //   image: maritime,
    // },
    // {
    //   key: 5,
    //   title: "CORPORATE",
    //   content:
    //     "We are proud to have one of the strongest corporate teams in the region. From company registration....",
    //   full_content:
    //     "We are proud to have one of the strongest corporate teams in the region. From company registration and incorporation to structuring and advising on the most innovative legal methods for boosting your business, we deliver practical and proven solutions for the most complex corporate matters: onshore, free zones, mergers, acquisitions, franchises and joint ventures, board resolutions, and strategic partnerships among others.",
    //   image: corporate,
    // },
    // {
    //   key: 6,
    //   title: "CONSTRUCTION",
    //   content:
    //     "Construction industry players benefit from our recognized track record of excellence. Our team are....",
    //   full_content:
    //     "Construction industry players benefit from our recognized track record of excellence. Our team is highly specialized legal advisors in the local and regional growth of the construction industry. Our team has advised and/or arbitrated on more than 20 large construction projects, involving contractors, project managers, developers, and investors.",
    //   image: construction,
    // },
    // {
    //   key: 7,
    //   title: "DATA PROTECTION AND DATA PRIVACY",
    //   content:
    //     "We help organizations who are already struggling with data protection and data privacy....",
    //   full_content:
    //     "We help organizations who are already struggling with data protection and data privacy to overcome such challenges and assist new ones by building their businesses with privacy in mind from the outset.",
    //   image: dataProtection,
    // },
    // {
    //   key: 8,
    //   title: "DOMESTIC & INTERNATIONAL ARBITRATION",
    //   content:
    //     "BinSevan Advocates and Legal Consultants’ arbitration team has a proven track record of successfully....",
    //   full_content:
    //     "Bin Sevan Advocates and Legal Consultants’ arbitration team has a proven track record of successfully advocating their clients’ cases, particularly, in real estate, construction, and oil and gas commercial arbitration in both the local and international arbitration arenas. The team is well-experienced in ad hoc as well as institutional arbitration including the Dubai International Arbitration Center (DIAC), the Abu Dhabi Commercial Conciliation and Arbitration Center (ADCCAC), the Dubai International Financial Center – London Court of International Arbitration Center (DIFC-LCIA), the International Chamber of Commerce (ICC) and the London Court of International Arbitration (LCIA).",
    //   image: domes,
    // },
    // {
    //   key: 9,
    //   title: "BLOCKCHAIN ",
    //   content:
    //     "When the wind of change blows, some build walls, while others build windmills!....",
    //   full_content:
    //     "When the wind of change blows, some build walls, while others build windmills! We at Bin Sevan Law Firm are keeping up with the rapid increase of the huge potentials of the blockchain technology in the legal field. Our deep knowledge in this new technology and mastery legal experience enable us to support our clients by providing a unique service that allows them to ride the wave and invest safely in various aspects of this revolutionary technology being described by some experts as (the “new internet”). Our forward-looking team is also poised to adopt at a depth and breadth blockchain-based arbitration in the near future as one of the many potentials of the usage of this technology in the legal field.",
    //   image: blockchain,
    // },
    // {
    //   key: 10,
    //   title: "METAVERSE",
    //   content:
    //     "Moving further into a world that blends the physical and digital....",
    //   full_content:
    //     "Moving further into a world that blends the physical and digital, the metaverse and its underlying Web3 infrastructure continue to develop. We explore potential paths for our clients who are engaged with the metaverse by considering potential business opportunities. Our Artificial Intelligent Lawyers are a representation of our firm in the virtual world and an important part of our firm’s Metaverse experience.",
    //   image: metaverse,
    // },
    // {
    //   key: 11,
    //   title: "BANKING & FINANCE",
    //   content:
    //     "Our banking and finance department regularly advises on asset finance and capital market transactions....",
    //   full_content:
    //     "Our banking and finance department regularly advises on asset finance and capital market transactions, private equity, finance structuring and acquisition, and funds. We serve a broad area of clients from the regional and international arena including corporations, institutions, and underwriters. Our team offers consistently complete and up-to-date financial solutions reflecting our commitment to maintaining a broad legal knowledge of the field.",
    //   image: banking,
    // },
    // {
    //   key: 12,
    //   title: "COMMERCIAL",
    //   content:
    //     "With a prolific involvement in multiple commercial sectors, our team has a sound practice encompassing....",
    //   full_content:
    //     "With a prolific involvement in multiple commercial sectors, our team has a sound practice encompassing commercial contracts, distribution, sales and purchase agreements, procurement, international commercial transactions, and much more. The complex nature of our clients’ commercial structures has committed us to offer superior legal advice under UAE and international jurisdictions, laws, commercial practices, and customs in several broad fields including transportation, the food and beverage industry, retail, technology, media, leisure and hospitality, real estate, and general commodities operations.",
    //   image: commercial,
    // },
    // {
    //   key: 13,
    //   title: "FAMILY LAW",
    //   content:
    //     "Family law. The stress of Marital breakdowns and child custody disputes can be overwhelming....",
    //   full_content:
    //     "Family law. The stress of Marital breakdowns and child custody disputes can be overwhelming for anyone. Compounding that stress with living in another country, with different laws and customs from your home country, can leave you feeling lost, and not knowing where to turn for advice. Our Family Law practice, at Bin Sevan Advocates, is unique in its kind in the UAE, as we can advise you on both English law and UAE law. Our team is dedicated to assisting UAE-based experts by providing them advice on matrimonial matters, child protection, and custody through the Dubai, Abu Dhabi, or English and Welsh Courts.",
    //   image: family,
    // },
    // {
    //   key: 14,
    //   title: "INTERNATIONAL OIL & GAS",
    //   content:
    //     "From production sharing and project service agreements to oil-field service....",
    //   full_content:
    //     "From production sharing and project service agreements to oil-field service contracts and facilities EPC contracts including Association of International Petroleum Negotiations (AIPN) and LOGIC standard contracts, Bin Sevan Advocates and Legal Consultants provides exceptional service to the industry.",
    //   image: oil,
    // },
    // {
    //   key: 15,
    //   title: "TECHNOLOGY, MEDIA & TELECOMMUNICATION",
    //   content:
    //     "Acting in one of the most prolific business hubs in the world, the Emirate of Dubai, our team has....",
    //   full_content:
    //     "Acting in one of the most prolific business hubs in the world, the Emirate of Dubai, our team has developed a tradition of advising operators, investors, and corporations on technology agreements, service contracts, suppliers relationship management licensing, all aspects of electronic commerce, and related protection and rights.",
    //   image: tech,
    // },
    // {
    //   key: 16,
    //   title: "CIVIL LAW",
    //   content:
    //     "As a core aspect of our legal practice in general, Civil Law, and, consequently, all the areas of....",
    //   full_content:
    //     "As a core aspect of our legal practice in general, Civil Law, and, consequently, all the areas of law derived from Civil Law are of crucial importance to us. Our team is trained to advise at all stages of Civil Law matters, starting with due diligence and legal research, drafting and negotiating, to Court representation and enforcement.",
    //   image: civil,
    // },
    // {
    //   key: 17,
    //   title: "CRIMINAL LAW",
    //   content:
    //     "With a sound tradition of excellence and a strong bi-lingual legal team, Bin Sevan Advocates &....",
    //   full_content:
    //     "With a sound tradition of excellence and a strong bi-lingual legal team, Bin Sevan Advocates & Legal Consultants advises and represents clients in all areas of the criminal procedures: preliminary proceedings, courts, executions, bail, and representation in front of the Courts, Public Prosecution and Police Departments all over the UAE.",
    //   image: criminal,
    // },
    // {
    //   key: 18,
    //   title: "RESTRUSCTURING & INSOLVENCY",
    //   content:
    //     "It is a well known fact that, for a company considering restructuring, a sound experience in negotiating....",
    //   full_content:
    //     "It is a well-known fact that, for a company considering restructuring, a sound experience in negotiating with creditors, mediation and debt scheduling is essential. Our team provides a complete range of legal services on the matter including advice on equity, consolidation, and disposal of assets, and exposure to insolvency. Our strategic alliances with experts, accountants, and auditors grant an efficient and correct execution of different assignments.",
    //   image: restructing,
    // },
  ];

  const ShowMoreHandler = () => {
    setIsshow(false);
  };
  const ShowLessHandler = () => {
    setIsshow(true);
  };

  return (
    <div className="services">
      <h1 className="service-heading">Our Services</h1>
      <div className="services-container">
        {showmore && (
          <div className="services-rows">
            {authCtx?.services.slice(0, 8).map(({ title, description, image }) => {
              return (
                <div
                  className="dropdown"
                  onClick={() => {
                    setOpen(true);
                    setTitle(title);
                    setContent(description);
                    setImage(image);
                  }}
                >{title}
                  <p className="dropdown-menu">{description.slice(0, 100)} .....</p>
                </div>
              );
            })}
          </div>
        )}
        {!showmore && (
          <div className="services-rows">
            {authCtx?.services.slice(0, authCtx?.services.length).map(({ title, description, image }) => {
              return (
                <div
                  className="dropdown"
                  onClick={() => {
                    setOpen(true);
                    setTitle(title);
                    setContent(description);
                    setImage(image);
                  }}
                >
                  {title}
                  <p className="dropdown-menu">{description.slice(0, 100)}</p>
                </div>
              );
            })}
          </div>
        )}
      </div>

      {showmore ? (
        <div className="show-more" onClick={ShowMoreHandler}>
          <p>SHOW MORE</p>
        </div>
      ) : (
        <Link className="permalinks__options" to="services">
          <div className="show-more" onClick={ShowLessHandler}>
            <p>SHOW LESS</p>
          </div>
        </Link>
      )}
      <PopupModal
        open={open}
        handleClose={handleClose}
        Title={Title}
        Content={Content}
        image={images}
      />
      <div id="team" />

    </div>
  );
};

export default OurServices;
