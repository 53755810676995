import React, { useState, useEffect } from 'react';
import { getAuth, signInWithEmailAndPassword, GoogleAuthProvider, EmailAuthProvider, updatePassword, reauthenticateWithCredential } from "firebase/auth";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  query,
  setDoc,
  where,
  addDoc,
  deleteDoc,
  updateDoc
} from "firebase/firestore"; import { initializeApp } from "firebase/app";
import { ref, getDownloadURL, uploadBytes, getStorage } from "firebase/storage"
import { firebaseConfig } from './config';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const AuthContext = React.createContext({
  selectedItem: {},
  setselectedItem: () => { },
  isLoggedIn: false,
  openteamModal: false,
  opencontactModal: false,
  openstoryModal: false,
  openservicesModal: false,
  openInsightsModal: false,
  openOthersModal: false,
  openMetaverseModal: false,
  onLogin: () => { },
  onLogout: () => { },
  setOpenteamModal: (openteamModal) => { },
  setOpencontactModal: (opencontactModal) => { },
  setOpenstoryModal: (openstoryModal) => { },
  setOpenservicesModal: (openservicesModal) => { },
  setOpenInsightsModal: (openInsightsModal) => { },
  setOpenOthersModal: (openOthersModal) => { },
  setOpenMetaverseModal: (openMetaverseModal) => { },
});

// Import the functions you need from the SDKs you need
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration


// Initialize Firebase

export const AuthContextProvider = (props) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [editmood, seteditmood] = useState(false);
  const [openteamModal, setOpenteamModal] = useState(false);
  const [opencontactModal, setOpencontactModal] = useState(false);
  const [openstoryModal, setOpenstoryModal] = useState(false);
  const [openservicesModal, setOpenservicesModal] = useState(false);
  const [openInsightsModal, setOpenInsightsModal] = useState(false);
  const [openOthersModal, setOpenOthersModal] = useState(false);
  const [openMetaverseModal, setOpenMetaverseModal] = useState(false);


  const app = initializeApp(firebaseConfig);
  const db = getFirestore(app);
  const storage = getStorage(app);
  const auth = getAuth();
  const provider = new GoogleAuthProvider();

  const [user, setUser] = useState(null);
  const [services, setServices] = useState([]);
  const [team, setTeam] = useState([]);
  const [contact, setContact] = useState([]);
  const [story, setStory] = useState([]);
  const [insights, setInsights] = useState([]);
  const [others, setOthers] = useState([]);
  const [metaverse, setMetaverse] = useState([]);
  const [selectedItem, setselectedItem] = useState([]);




  const signInWithEmailAndPasswordHandler = (email, password) => {
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;
        // ...
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
      });
  };

  const changePassword = async (currentPassword, newPassword) => {
    const user = auth.currentUser;
    
    // Step 1: Reauthenticate user with current credentials
    const credentials = EmailAuthProvider.credential(user.email, currentPassword);
  
    try {
      await reauthenticateWithCredential(user, credentials);
      console.log('User reauthenticated successfully.');
  
      // Step 2: Update password to new password
      await updatePassword(user, newPassword);
      console.log('Password updated successfully!');
      
    toast.success('Password changed successfully',{hideProgressBar:true});
      // Optionally, handle UI updates or notifications for success
    } catch (error) {
      console.error('Error updating password:', error.message);
      // Handle error updating password
      // Optionally, handle UI updates or notifications for error
    toast.error('Error updating password. Please check your current password.',{hideProgressBar:true});
    }
  };
  


  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(null);
      }
    });
    return unsubscribe;
  }, [auth]);


  const signOut = () => {
    auth.signOut().then(() => {
      setUser(null);
      // Sign-out successful.
    }).catch((error) => {
      // An error happened.
    });
  }

  const addService = async (service, file) => {
    const storageRef = ref(storage, `services/${file.name}`);
    uploadBytes(storageRef, file).then((snapshot) => {
      getDownloadURL(snapshot.ref).then(async (url) => {
        const docRef = await addDoc(collection(db, "services"), {
          ...service,
          image: url,

        });
        setServices(prev => [...prev, { ...service, id: docRef.id }]);

      });
    });

  }

  const addTeam = async (team, file) => {
    const storageRef = ref(storage, `team/${file.name}`);
    uploadBytes(storageRef, file).then((snapshot) => {
      getDownloadURL(snapshot.ref).then(async (url) => {
        const docRef = await addDoc(collection(db, "team"), {
          ...team,
          image: url,

        });
        setTeam(prev => [...prev, { ...team, id: docRef.id }]);

      });
    });
  }
  
  const addContact = async (contact, file) => {
        const docRef = await addDoc(collection(db, "contact"), {
          ...contact,

        });
        setContact(prev => [...prev, { ...contact, id: docRef.id }]);
  }
  
  const addStory = async (story, file) => {
    const docRef = await addDoc(collection(db, "story"), {
      ...story,

    });
    setStory(prev => [...prev, { ...story, id: docRef.id }]);
}

  const sortArray = (array) => {
    array.sort((a, b) => a.key - b.key);
  }


  const addInsights = async (insights, file) => {
    const storageRef = ref(storage, `insights/${file.name}`);
    uploadBytes(storageRef, file).then((snapshot) => {
      getDownloadURL(snapshot.ref).then(async (url) => {
        const doRef = await addDoc(collection(db, "insights"), {
          ...insights,
          image: url,


        });
        setInsights(prev => [...prev, { ...insights, id: doRef.id }]);

      });
    });
  }
  
  const addOthers = async (others, file) => {
    const storageRef = ref(storage, `others/${file.name}`);
    uploadBytes(storageRef, file).then((snapshot) => {
      getDownloadURL(snapshot.ref).then(async (url) => {
        const doRef = await addDoc(collection(db, "others"), {
          ...others,
          image: url,


        });
        setOthers(prev => [...prev, { ...others, id: doRef.id }]);
        console.log(doRef.id, "others");

      });
    });
  }
  
  const addMetaverse = async (metaverse, file) => {
    const storageRef = ref(storage, `metaverse/${file.name}`);
    uploadBytes(storageRef, file).then((snapshot) => {
      getDownloadURL(snapshot.ref).then(async (url) => {
        const doRef = await addDoc(collection(db, "metaverse"), {
          ...metaverse,
          image: url,


        });
        setMetaverse(prev => [...prev, { ...metaverse, id: doRef.id }]);
        console.log(doRef.id, "metaverse");

      });
    });
  }



  // Theses functions are redundant for readablity
  // If possible, Refactor them to one function with the collection name as a parameter
  // if you need help, ask Ali



  const getServices = async () => {
    await getDocs(collection(db, "services")).then((querySnapshot) => {
      const services = [];
      querySnapshot.forEach((doc) => {
        services.push({ ...doc.data(), id: doc.id });
      });
      sortArray(services);
      setServices(services);
    });

  }

  const getContact = async () => {
    await getDocs(collection(db, "contact")).then((querySnapshot) => {
      const contact = [];
      querySnapshot.forEach((doc) => {
        contact.push({ ...doc.data(), id: doc.id });
      });
      setContact(contact);
    });
  }
  
  const getStory = async () => {
    await getDocs(collection(db, "story")).then((querySnapshot) => {
      const story = [];
      querySnapshot.forEach((doc) => {
        story.push({ ...doc.data(), id: doc.id });
      });
      setStory(story);
    });
  }

  const getTeam = async () => {
    await getDocs(collection(db, "team")).then((querySnapshot) => {
      const team = [];
      querySnapshot.forEach((doc) => {
        team.push({ ...doc.data(), id: doc.id });
        console.log("test11")
      });
      sortArray(team);
      setTeam(team);
      console.log("test")
    });

  }
  
  const getInsights = async () => {
    await getDocs(collection(db, "insights")).then((querySnapshot) => {
      const insights = [];
      querySnapshot.forEach((doc) => {
        insights.push({ ...doc.data(), id: doc.id });
      });
      sortArray(insights);
      setInsights(insights);
    });

  }
  
  const getOthers = async () => {
    await getDocs(collection(db, "others")).then((querySnapshot) => {
      const others = [];
      querySnapshot.forEach((doc) => {
        others.push({ ...doc.data(), id: doc.id });
      });
      setOthers(others);
    });

  }
  
  const getMetaverse = async () => {
    await getDocs(collection(db, "metaverse")).then((querySnapshot) => {
      const metaverse = [];
      querySnapshot.forEach((doc) => {
        metaverse.push({ ...doc.data(), id: doc.id });
      });
      setMetaverse(metaverse);
    });

  }


  const deleteService = async (id) => {
    await deleteDoc(doc(db, "services", id)).then(() => {
      setServices(services.filter((service) => service.id !== id));
    });

  }

  const deleteTeam = async (id) => {
    await deleteDoc(doc(db, "team", id)).then(() => {
      setTeam(team.filter((team) => team.id !== id));
    });
  }
  
  const deleteContact = async (id) => {
    await deleteDoc(doc(db, "contact", id)).then(() => {
      setContact(contact.filter((contact) => contact.id !== id));
    });
  }

  
  const deleteStory = async (id) => {
    await deleteDoc(doc(db, "story", id)).then(() => {
      setStory(story.filter((story) => story.id !== id));
    });
  }

  const deleteInsights = async (id) => {
    await deleteDoc(doc(db, "insights", id)).then(() => {
      setInsights(insights.filter((insight) => insight.id !== id));
    });

  }
  
  const deleteOthers = async (id) => {
    console.log(id, "deleting");
    await deleteDoc(doc(db, "others", id)).then(() => {
      console.log("deleted");
      setOthers(others.filter((other) => other.id !== id));
    });

  }
  
  const deleteMetaverse = async (id) => {
    console.log(id, "deleting");
    await deleteDoc(doc(db, "metaverse", id)).then(() => {
      console.log("deleted");
      setMetaverse(metaverse.filter((other) => other.id !== id));
    });

  }

  const updateService = async (id, service, file) => {
    if (file instanceof File) {
      const storageRef = ref(storage, `services/${file.name}`);
      uploadBytes(storageRef, file).then((snapshot) => {
        getDownloadURL(snapshot.ref).then(async (url) => {
          await updateDoc(doc(db, "services", id), {
            ...service,
            image: url,

          });
        });
      });
    } else {
      await updateDoc(doc(db, "services", id), {
        ...service,
      });
    } 
    setServices(services.map((serv) => serv.id === id ? { ...service, ...service } : serv));
  }

  const updateTeam = async (id, teamMember, file) => {
    if (file instanceof File) {
      const storageRef = ref(storage, `team/${file.name}`);
      uploadBytes(storageRef, file).then((snapshot) => {
        getDownloadURL(snapshot.ref).then(async (url) => {
          await updateDoc(doc(db, "team", id), {
            ...team,
            image: url,

          });
        });
      });
    } else {
      await updateDoc(doc(db, "team", id), {
        ...teamMember
      });
    }
    setTeam(team.map((team) => team.id === id ? { ...teamMember, ...teamMember } : team));

  }

  const updateContact = async (id, contact, file) => {
          await updateDoc(doc(db, "contact", id), {
            ...contact,
          });
    setContact(contact.map((con) => con.id === id ? { ...contact, ...contact } : con));
  }

  
  const updateStory = async (id, story, file) => {
    await updateDoc(doc(db, "story", id), {
      ...story,
    });
setStory(story.map((str) => str.id === id ? { ...story, ...story } : str));
}



  const updateInsights = async (id, insight, file) => {
    if (file instanceof File) {
      const storageRef = ref(storage, `insights/${file.name}`);
      uploadBytes(storageRef, file).then((snapshot) => {
        getDownloadURL(snapshot.ref).then(async (url) => {
          await updateDoc(doc(db, "insights", id), {
            ...insights,
            image: url,
          });
        });
      });

    } else {
      await updateDoc(doc(db, "insights", id), {
        ...insight
      });
      setInsights(insights.map((insig) => insig.id === id ? { ...insight, ...insight } : insig));

    }
  }
  
const updateOthers = async (id, others, file) => {
  if (file instanceof File) {
    const storageRef = ref(storage, `others/${file.name}`);
    uploadBytes(storageRef, file).then((snapshot) => {
      getDownloadURL(snapshot.ref).then(async (url) => {
        await updateDoc(doc(db, "others", id), {
          ...others,
          image: url,
        });
      });
    });

  } else {
  await updateDoc(doc(db, "others", id), {
    ...others
  });

}
}

const updateMetaverse = async (id, metaverse, file) => {
  if (file instanceof File) {
    const storageRef = ref(storage, `metaverse/${file.name}`);
    uploadBytes(storageRef, file).then((snapshot) => {
      getDownloadURL(snapshot.ref).then(async (url) => {
        await updateDoc(doc(db, "metaverse", id), {
          ...metaverse,
          image: url,
        });
      });
    });

  } else {
  await updateDoc(doc(db, "metaverse", id), {
    ...metaverse
  });

}
}

  const moveTeam = async (id, teamMember, key) => {
    
      await updateDoc(doc(db, "team", id), {
        ...teamMember,
        key: key,
      });
    // setTeam(team.map((team) => team.id === id ? { ...teamMember, ...teamMember } : team));
    getTeam();
  }

  const moveService = async (id, service, key) => {
    
    await updateDoc(doc(db, "services", id), {
      ...service,
      key: key,
    });
  // setTeam(team.map((team) => team.id === id ? { ...teamMember, ...teamMember } : team));
  getServices();
}

const moveInsight = async (id, insight, key) => {
    
  await updateDoc(doc(db, "insights", id), {
    ...insight,
    key: key,
  });
// setTeam(team.map((team) => team.id === id ? { ...teamMember, ...teamMember } : team));
getInsights();
}


  useEffect(() => {
    getServices();
    getTeam();
    getContact();
    getStory();
    getInsights();
    getOthers();
    getMetaverse();
  }, []);






  // useEffect(() => {
  //   const storedUserLoggedInInformation = localStorage.getItem('isLoggedIn');

  //   if (storedUserLoggedInInformation === '1') {
  //     setIsLoggedIn(true);
  //   }
  // }, []);

  const logoutHandler = () => {
    // localStorage.removeItem('isLoggedIn');
    setIsLoggedIn(false);
  };

  const loginHandler = () => {
    // localStorage.setItem('isLoggedIn', '1');
    setIsLoggedIn(true);
  };

  const openmodalteamhandler = (openteamModal) => {
    setOpenteamModal(openteamModal)
  }
  
  const openmodalcontacthandler = (opencontactModal) => {
    setOpencontactModal(opencontactModal)
  }
  
  const openmodalstoryhandler = (openstoryModal) => {
    setOpenstoryModal(openstoryModal)
  }

  const seteditmoodhandler = (editmood) => {
    seteditmood(editmood)
  }

  const openmodalserviceshandler = (openservicesModal) => {
    setOpenservicesModal(openservicesModal)
  }

  const openmodalinsightshandler = (openInsightsModal) => {
    setOpenInsightsModal(openInsightsModal)
  }
  
  const openmodalothershandler = (openOthersModal) => {
    setOpenOthersModal(openOthersModal)
  }
  
  const openmodalmetaversehandler = (openMetaverseModal) => {
    setOpenMetaverseModal(openMetaverseModal)
  }

  const setselectedItemhandler = (selectedItem) => {
    setselectedItem(selectedItem)
  }

  const contextValue = {
    user,
    signInWithEmailAndPasswordHandler,
    changePassword,
    setOpenservicesModal,
    setOpenteamModal,
    setOpencontactModal,
    setOpenstoryModal,
    seteditmood,
    setOpenInsightsModal,
    setOpenOthersModal,
    setOpenMetaverseModal,
    signOut,
    selectedItem: selectedItem,
    setselectedItem: setselectedItemhandler,
    openservicesModal,
    openmodalserviceshandler,
    openmodalteamhandler,
    openmodalcontacthandler,
    openmodalstoryhandler,
    openmodalinsightshandler,
    setOpenOthersModal,
    addService,
    addTeam,
    addContact,
    addStory,
    addInsights,
    addOthers,
    addMetaverse,
    deleteService,
    deleteTeam,
    deleteContact,
    deleteStory,
    deleteInsights,
    deleteOthers,
    deleteMetaverse,
    updateService,
    updateTeam,
    updateContact,
    updateStory,
    updateInsights,
    updateOthers,
    updateMetaverse,
    moveTeam,
    moveService,
    moveInsight,
    services,
    team,
    contact,
    story,
    insights,
    others,
    metaverse,
    openteamModal,
    opencontactModal,
    openstoryModal,
    editmood,
    openInsightsModal,
    openOthersModal,
    openMetaverseModal,
  }

  return (
    <AuthContext.Provider value={contextValue}>
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContext;

