import React, { useContext, useEffect, useState } from "react";
import classes from "./Services.module.css";
// import AuthContext from "../../../Common/auth-context";

import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import CheckIcon from "@mui/icons-material/Check";

import TextField from "@mui/material/TextField";
import ServicesModal from "./Services Modal/ServicesModal";
import AuthContext from "../../auth-context";
// import ItemModal from "./Item Modal/ItemModal";
// import ItemSearchBox from "./Items Search/ItemsSearch";
import CreateIcon from "@mui/icons-material/Create";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';



export const items = [
 ];


const headCells = [
  {
    id: "sort",
    label: "Sort",
  },
  {
    id: "title",
    label: "Title",
  },
  {
    id: "subdescription",
    label: "SubDescription",
  },
  {
    id: "edit",
    label: "Edit",
  },
];

function EnhancedTableHead(props) {

  return (
    <TableHead>
      <TableRow className={classes.headingRow}>
        {headCells.map((headCell) => (
          <TableCell className={classes.headingRow} key={headCell.id}>
            <b>{headCell.label}</b>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  // numSelected: PropTypes.number.isRequired,
  // onSelectAllClick: PropTypes.func.isRequired,
  // rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const { numSelected } = props;
  const authCtx = useContext(AuthContext);

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
      className={classes.tableHeading}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: "1 1 100%" }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Items
        </Typography>
      )}

      {/* <ItemSearchBox data={items} cat={menu} /> */}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
      <AddCircleIcon onClick={() => { authCtx.setOpenItemModal(true); }} />
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  // numSelected: PropTypes.number.isRequired,
};

const ServicesTable = () => {
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const authCtx = useContext(AuthContext);
  const [openModule, setopenModule] = useState("Add Service");

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const handleChange = (event) => {
    setDense(event.target.checked);
  };

  const addService = () => {
    authCtx.seteditmood(false);
    authCtx.setselectedItem({});
    setopenModule("Add Service");
    console.log("add service");
    authCtx.setOpenservicesModal(true);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - items.length) : 0;

  const handleClose = () => {
    authCtx.setOpenservicesModal(false);
  };

  const handleEdit = (id) => {
    console.log("edit", id);
    authCtx.setOpenservicesModal(true);
  };

  const handleDelete = (id) => {
    console.log("delete", id);
    authCtx.deleteService(id);
  };
  const [rows, setRows] = useState(authCtx?.services);
  useEffect(()=>{
    setRows(authCtx?.services)
    console.log("11111111")
  },[authCtx?.services])
  const handleMoveUp = (index) => {
    if (index === 1) return; // Already at the bottom

    const updatedRows = [...rows];
    // [updatedRows[index -1], updatedRows[index]] = [updatedRows[index], updatedRows[index -1]];
    // setRows(updatedRows); 
    authCtx.moveService(updatedRows[index-1].id, updatedRows[index-1],updatedRows[index-2].key);
    authCtx.moveService(updatedRows[index-2].id, updatedRows[index-2],updatedRows[index-1].key);

  };
  const handleMoveDown = (index) => {
    if (index === rows.length ) return; // Already at the bottom

    const updatedRows = [...rows];
    // [updatedRows[index -1], updatedRows[index]] = [updatedRows[index], updatedRows[index -1]];
    // setRows(updatedRows); 
     authCtx.moveService(updatedRows[index-1].id, updatedRows[index-1],updatedRows[index].key);
     authCtx.moveService(updatedRows[index].id, updatedRows[index],updatedRows[index-1].key);
  };


  return (
    <div className={classes.itemsContainer}>
      <div className={classes.itemsHeading}>
        <div className={classes.headContainer}>
          <p className={classes.itemHead}>Services</p>
          <p className={classes.subHead}>Manage your Services</p>
        </div>
        <AddCircleIcon onClick={addService} className={classes.addbtn} />
      </div>
      <div className={classes.itemsTableContainer}>
        <Paper sx={{ width: "100%", mb: 2 }}>
          {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={dense ? "small" : "medium"}
            >
              <EnhancedTableHead
                numSelected={selected.length}
                rowCount={items.length}
              />
              <TableBody>
                {rows
                  .slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                  .map((items, index1) => {
                    return (
                      <TableRow
                        className={classes.itemRow}
                        onDoubleClick={() => {
                          authCtx.setOpenservicesModal(true);
                          setopenModule("Edit Your Service");
                          authCtx.setselectedItem(items);
                          authCtx.seteditmood(true);
                        }}
                      >
                        <TableCell className={classes.rowData}><ArrowUpwardIcon onClick={() => {handleMoveUp(items.key);}} disabled={items.key === 0}/> <ArrowDownwardIcon onClick={() => {handleMoveDown(items.key);}} disabled={items.key === 0}/></TableCell>
                        <TableCell className={classes.rowData}>{items.title}</TableCell>
                        <TableCell >{items.description}</TableCell>
                        <TableCell align="left">
                          <div className={classes.iconsCon}>
                            <CreateIcon
                              onClick={() => {
                                authCtx.setOpenservicesModal(true);
                                setopenModule("Edit Your Service");
                                authCtx.setselectedItem(items);
                                authCtx.seteditmood(true);
                                authCtx.setOpenservicesModal(true);
                                setopenModule("Edit Your Service");
                                authCtx.setselectedItem(items);
                                authCtx.seteditmood(true);
                              }}
                              class={classes.edit}
                            />
                            <DeleteForeverIcon
                              class={classes.freeze}
                              onClick={() => {
                                handleDelete(items.id);
                              }}
                            /></div>
                        </TableCell>
                      </TableRow>
                    );
                  }
                  )}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: (dense ? 33 : 53) * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={authCtx?.services.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
        <ServicesModal handleClose={handleClose}
          lable={openModule} />
      </div>
    </div>
  );
};
export default ServicesTable

