import styled from "styled-components";

export const InsightsContainer = styled.div`
position:relative;
width: 100%;
height: fit-content;
display: grid;
grid-template-columns: 50% 50%;
margin-top: 5%;
margin-bottom: 5%;

/* =========== MEDIA VIEW (SMALL DEVICES) =========== */
@media screen and (max-width: 950px) {
    grid-template-columns: 100%;
}
`;
export const Insightswrapper = styled.div`
position:relative;
width: 100%;
height: fit-content;
display: flex;
flex-direction: row;

`;

export const Image = styled.div`
position: relative;
height: 600px;
background: black;

/* =========== MEDIA VIEW (SMALL DEVICES) =========== */
@media screen and (max-width: 950px) {
    height: 350px;
}
`;

export const DescriptionWrapper = styled.div`
position: relative;
height: 600px;
align-items: center;
display: flex;
flex-direction: column;
background: var(--color-primary);

/* =========== MEDIA VIEW (SMALL DEVICES) =========== */
@media screen and (max-width: 950px) {
    height: 350px;
}
`;

export const InsightsHeading = styled.h1`
position: relative;
font-family: 'Superclarendon';
font-style: normal;
font-weight: 700;
font-size: calc(1.5rem + 1vw);
color: #ffffff;
border-bottom:1px solid #ffffff;
width: fit-content;
margin-left: -50%;
margin-top: 10%;

/* =========== MEDIA VIEW (SMALL DEVICES) =========== */
@media screen and (max-width: 950px) {
    margin-top: 12%;
}
`;

export const SwipperContainer = styled.div`
position: relative;
font-family: 'Montserrat';
font-style: normal;
font-weight: 700;
font-size: 35px;
color: #ffffff;
width: 96%;
align-items: center;


`;
export const Description = styled.div`
position: relative;
display: grid;
grid-template-columns: 100%;
margin-top:7rem;
row-gap:10rem;
cursor: pointer;
/* =========== MEDIA VIEW (SMALL DEVICES) =========== */
@media screen and (max-width: 950px) {
    margin-top:2rem;
row-gap:4rem;
}
`;


export const Text = styled.div`
position: relative;
width: 80%;
margin-left: 10%;


font-family: 'Montserrat';
font-style: normal;
font-size: calc(0.7rem + 0.5vw);
color: #ffffff;
text-align: start;

/* =========== MEDIA VIEW (SMALL DEVICES) =========== */
@media screen and (max-width: 950px) {
    text-align: center;
    width: 75%;
   left: 0.4rem;
}
`;

export const Footer = styled.div`
position:relative;
width: 90%;
display:grid;
grid-template-columns:55% 45%;

/* =========== MEDIA VIEW (SMALL DEVICES) =========== */
@media screen and (max-width: 950px) {
   grid-template-columns: 40% 90%;
}
`; 

export const Date = styled.div`
position: relative;
font-family: 'Montserrat';
font-style: normal;
font-weight: 500;
font-size: calc(0.6rem + 0.5vw);


/* =========== MEDIA VIEW (SMALL DEVICES) =========== */
@media screen and (max-width: 950px) {
   left:2rem;
   font-weight: 500;
}
`;

export const Place = styled.div`
position: relative;
font-family: 'Montserrat';
font-style: normal;
font-weight: 500;
font-size: calc(0.6rem + 0.5vw);



/* =========== MEDIA VIEW (SMALL DEVICES) =========== */
@media screen and (max-width: 950px) {
   font-weight: 500;
   right: 2rem;
}
`;