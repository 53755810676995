import React, { useEffect, useState } from 'react';
import classes from "./footer.module.css";
import Logoimg from '../../../assets/miniWhiteLogo.svg';
import { Link } from 'react-scroll';
import AuthContext from "../../Admin Panel/auth-context";


function Footer() {
  const authCtx = React.useContext(AuthContext);

  const [contactEmail, setcontactEmail] = useState("");
  const [Copyright, setCopyright] = useState("");
  const [contactTitle, setcontactTitle] = useState("");
  const [contactLocation, setcontactLocation] = useState("");
  const [contactPhoneNumber, setcontactPhoneNumber] = useState("");
  const [POBox, setPOBox] = useState("");

  useEffect(() => {

    authCtx?.contact.forEach((item) => {

      if (item.title === "Email") {
        setcontactEmail(item.description)
      }
      if (item.title === "Copyright") {
        setCopyright(item.description)
      }
      if (item.title === "Title") {
        setcontactTitle(item.description)
      }
      if (item.title === "Location") {
        setcontactLocation(item.description)
      }
      if (item.title === "Phone Number") {
        setcontactPhoneNumber(item.description)
      }
      if (item.title === "P.O.Box") {
        setPOBox(item.description)
      }
   
    });}, [authCtx.contact ]); 
    
  return (
    <footer>
      <Link  className={classes.footer__logo} to='home' smooth={true}  duration={500} >
        <img src={Logoimg} alt="binsevan-logo" />
      </Link>

      <div className={classes.permalinks}>
        {/* <ul> */}
        <Link  className={classes.permalinks__options} to='story' smooth={true}  duration={500} > Our Story</Link>
        <Link  className={classes.permalinks__options} to='services' smooth={true}  duration={500}>Our Services</Link>
        <Link  className={classes.permalinks__options} to='team' smooth={true}  duration={500}>Team</Link>
        <Link  className={classes.permalinks__options} to='insights' smooth={true}  duration={500}>Insights</Link>
        {/* <Link  className='permalinks__options' to='testimonials' smooth={true}  duration={500}>Testimonials</Link> */}
        <Link  className={classes.permalinks__options} to='contact' smooth={true}  duration={500}>Contact us</Link>
        {/* </ul> */}
      </div>

      <div className={classes.footer__socials}>
      {/* <ul> */}        <a  className={classes.permalinks__head} href='https://www.google.com/maps/place/Prime+Tower+-+Business+Bay+-+Dubai+-+United+Arab+Emirates/@25.1884122,55.2693277,17z/data=!3m1!4b1!4m6!3m5!1s0x3e5f682d1ff90fe7:0x14375ae978cdf590!8m2!3d25.1884074!4d55.2715164!16s%2Fm%2F03grhtl' target='_blank'>Site Map</a>

        <p className={classes.permalinks__head}>Company</p>
        <p>Help & Support</p>
        <p>Terms & Conditions</p>
        <p>Privacy Policy</p>
      {/* </ul> */}
      </div>

      <div className={classes.footer__copyright}>
       {/* <ul> */}
       <p>{Copyright}</p>
            <p>{contactEmail}</p>
            <p>{contactPhoneNumber}</p>
            <p>{POBox}</p>
        <p className={classes.footer_address}>{contactLocation}</p>
       {/* </ul> */}
      </div>
    </footer>
  )
}

export default Footer;