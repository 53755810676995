import styled from "styled-components";

export const Layout = styled.div`
position: fixed;
top: 50% !important;
left: 50%;
transform: translate(-50%, -50%);
width:80%;
height: 70vh;

background: transparent;

/* =========== MEDIA VIEW (SMALL DEVICES) =========== */
@media screen and (max-width: 750px) {
    /* margin-top: 28%; */
    }
`;

export const ModalBox = styled.div`
position: relative;
width: 100%;
height: 100%;
align-items: center;
display: grid;
grid-template-columns: 37% 50%;

background: #FFFFFF;
box-shadow: 0px 8px 20px black;
border-color:transparent;


/* =========== MEDIA VIEW (SMALL DEVICES) =========== */
@media screen and (max-width: 750px) {
    display: flex;
    flex-direction:column;
    height: fit-content;
}
`;

export const Image = styled.div`
position: relative;
height: 80%;
margin-left:5%;
border: none;


/* =========== MEDIA VIEW (SMALL DEVICES) =========== */
@media screen and (max-width: 750px) {
    width: 90%;
    height: calc(12rem + 10vw);
    image-orientation: from-image;
    margin-bottom: 5%;
    margin-top: 5%;
    left: 0%;
    margin-left: 0%;

}
`; 
export const TextContainer = styled.div`
position: relative;
width: 100%;
height: fit-content;
`


export const Heading = styled.div`
position: relative;
margin-left: 2rem;
width: 100%;
padding-bottom: 1rem;


font-family: 'Montserrat';
font-style: normal;
font-weight: 700;
font-size: calc(0.7rem + 0.7vw);
line-height: 113.5%;
/* or 45px */

display: flex;
align-items: center;
letter-spacing: -0.02em;
text-transform: uppercase;

color: #101820;

/* =========== MEDIA VIEW (SMALL DEVICES) =========== */
@media screen and (max-width: 750px) {
    width: 85%;
    padding-right:1rem ;
}
`;

export const Paragraph = styled.p`
position: relative;
width: 100%;
margin-left: 2rem;
text-transform: lowercase;
overflow-y: scroll;
height: 300px;
padding-right: 1rem;  

font-family: 'Montserrat';
font-style: normal;
font-weight: 400;
font-size: calc(0.5rem + 0.7vw);
line-height: 30px;
/* or 27px */

display: flex;
letter-spacing: -0.02em;
text-transform: none !important;

color: #000000;

/* =========== MEDIA VIEW (SMALL DEVICES) =========== */
@media screen and (max-width: 750px) {
    width: 85%;
    padding-right:1rem ;

    font-size: calc(0.5rem + 0.7vw);
    line-height: 20px;
    margin-bottom: 3rem;
    overflow-y: scroll;
    align-items:flex-start;
    height: 150px;  
}
/* =========== MEDIA VIEW (SMALL DEVICES) =========== */
@media screen and (max-width: 750px) {
    width: 85%;
    height: 120px;   
    margin-bottom: 2rem;
    margin-left: 1rem;
    padding-right:0.5rem ;
}
`;