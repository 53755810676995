import styled from "styled-components";
import HeadingImg from '../../../assets/heading-img.webp'

export const HeadingContainer = styled.div`
position:relative;
top:80px;
width: 100%;
height: 630px;

@media screen and (max-width: 1100px) {
margin-bottom: 1.5rem;
}
  /* =========== MEDIA VIEW (SMALL DEVICES) =========== */
  @media screen and (max-width: 820px) {
margin-bottom: 8rem;
height: 595px;
}
`;

export const HeadingImage = styled.div`
position: relative;
width: 100%;
height: 100%;

background: url(${HeadingImg});
background-size: cover;
background-repeat: no-repeat;


/* transform: perspective(10000px); */
`;

export const AvatarCon = styled.div`
position: absolute;
width: 650px;
height: 100px;
right: 0%;
bottom:0%;
display: grid;
grid-template-columns: 80% 15%;
z-index: 10;

background: linear-gradient(356.74deg, #000000 -8.55%, rgba(0, 0, 0, 0) 105.46%, rgba(0, 0, 0, 0) 187.89%);


/* =========== MEDIA VIEW (SMALL DEVICES) =========== */
@media screen and (max-width: 600px) {
    width: 100%;
    bottom: 0%;
    height: 120px;
}
`;

export const AvatarIcon = styled.div`
position: relative;
width: 75px;
height: 75px;
align-self: center;
cursor: pointer;


  /* =========== MEDIA VIEW (SMALL DEVICES) =========== */
  @media screen and (max-width: 600px) {
display: none;
}
`;

export const AvatarDescription = styled.div`
position: relative;

font-family: 'Superclarendon';
font-style: normal;
font-weight: 400;
font-size: 24px;
line-height: 30px;
margin-left: 2rem;
text-align:start;



color: #FFFFFF;

 /* =========== MEDIA VIEW (SMALL DEVICES) =========== */
 @media screen and (max-width: 600px) {
    font-size: 18px;
    left: 15%; 
}

`;

export const P = styled.p`
position: absolute;
width: 199px;
height: 55px;
left: 300px;
top: 40px;

font-family: 'Superclarendon';
font-style: normal;
font-weight: 700;
font-size: 14px;
line-height: 55px;

text-align: center;
letter-spacing: 0.015em;

color: #F7F7F7;

/* =========== MEDIA VIEW (SMALL DEVICES) =========== */
@media screen and (max-width: 600px) {
    position: absolute;
    left: 12%;
    top: 50%;
    height: fit-content;
}
`;

export const VideoContainer = styled.div`
position:  fixed;
width: calc(30rem + 15vw);
height: calc(18rem + 15vw);
right: -9%;
bottom: -9%;
z-index: 10;
/* 
=========== MEDIA VIEW (SMALL DEVICES) =========== */
@media screen and (max-width: 600px) {
    /* right: -30%; */
  }
`;

export const IconContainer = styled.image`
display: flex;
width: fit-content;
height: fit-content;
background-color: white;
cursor: pointer;
border-radius: 2rem;
margin-left: 70%;
bottom: 0%;
z-index: 10;
`;
