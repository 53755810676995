import * as React from "react";
import Modal from "@mui/material/Modal";
import {
  Layout,
  ModalBox,
  TextContainer,
  Image,
  Heading,
  Paragraph,
} from "./PopupModalElements";
import { BlurImg } from "../BlurHash/BlurImage";

export default function PopupModal(props) {
  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Layout>
          <ModalBox>
            <Image>
              <BlurImg
                blurhash="LGF5]+Yk^6#M@-5c,1J5@[or[Q6."
                src={props.image}
                alt="services-image"
                className="image11"
                punch={1}
              />{" "}
            </Image>
            <TextContainer>
              <Heading>{props.Title}</Heading>
              <Paragraph>{props.Content}</Paragraph>
            </TextContainer>
          </ModalBox>
        </Layout>
      </Modal>
    </div>
  );
}
