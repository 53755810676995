import React, { useEffect, useState } from 'react';
import { StoryContainer,TextWrapper1, StoryHeading, Numbers,Number, Line1, 
    TextWrapper, Text, Image, Image1 } from './StoryElements';
import IMG from '../../../assets/OurStory.png';
import AuthContext from "../../Admin Panel/auth-context";

const Story = ({
  headline, 
}) => {
    const authCtx = React.useContext(AuthContext);

    const [nmbr1, setnmbr1] = useState(true);
    const [nmbr2, setnmbr2] = useState(false);
    const [nmbr3, setnmbr3] = useState(false);

    const Nb1Handler = () =>{
        setnmbr1(true);
        setnmbr2(false);
        setnmbr3(false);
    };
    const Nb2Handler = () =>{
        setnmbr1(false);
        setnmbr2(true);
        setnmbr3(false);
    };
    const Nb3Handler = () =>{
        setnmbr1(false);
        setnmbr2(false);
        setnmbr3(true);

    };
    const [description1, setdescription1] = useState([]);
  const [description2, setdescription2] = useState([]);
  const [description3, setdescription3] = useState([]);
  const [imagee, setImage] = useState();

  useEffect(() => {

    authCtx?.story.forEach((item) => {

      if (item.key === 1) {
        const des = item.description.split('\\n');
        setdescription1(des)
      }
      if (item.key === 2) {
        const des = item.description.split('\\n');
        setdescription2(des)
      }
      if (item.key === 3) {
        const des = item.description.split('\\n');
        setdescription3(des)
      }
    });}, [authCtx.story]);

    useEffect(() => {

      authCtx?.others.forEach((item) => {
  
        if (item.key === 1) {
          setImage(item.image)
        }
      });}, [authCtx.others]);
    
  return (
 
    <>
    <StoryContainer>
        <div></div>
        <Numbers>
           <Number onClick={Nb1Handler} style={nmbr1?{color: '#c3141f'}:{}}>01</Number>
           { nmbr1 && <Line1>WHO ARE WE </Line1> }
           <Number onClick={Nb2Handler} style={nmbr2?{color: '#c3141f'}:{}}>02</Number>
           { nmbr2 && <Line1>OUR COMMITMENT</Line1> }
           <Number onClick={Nb3Handler} style={nmbr3?{color: '#c3141f'}:{}}>03</Number>
           { nmbr3 && <Line1>VALUE</Line1> }
          
        </Numbers>
        <TextWrapper1>
            <TextWrapper>
                <StoryHeading>{headline}</StoryHeading>
                
                {nmbr1 && <Text>
                    {description1.map((line, index) => (
                    <p key={index}>{line}</p>
                  ))}
                  </Text>}
              
                {nmbr2 && <Text>{description2.map((line, index) => (
                    <p key={index}>{line}</p>
                ))}</Text>}
                {nmbr3 && <Text>{description3.map((line, index) => (
                    <p key={index}>{line}</p>
                    ))}</Text>}
            
                

            </TextWrapper>
        </TextWrapper1>
        <Image>
            <img src={imagee} alt='story-img' width="100%" height="100%" /></Image>
        <div id='services'/>
    </StoryContainer>
    <Image1>
            <img src={imagee} alt='story-mobile' width="100%" height="100%" /></Image1>
    {/* <button onClick={()=>console.log(authCtx.story)}>oejnoi</button> */}
    
    </>
  )
}

export default Story;


