import { useContext, useState } from "react";
import classes from "./AdminPanel.module.css";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import InsightsIcon from '@mui/icons-material/Insights';
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import AssuredWorkloadIcon from '@mui/icons-material/AssuredWorkload';
import Groups2Icon from '@mui/icons-material/Groups2';
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
import LogoutIcon from "@mui/icons-material/Logout";
import Team from "./Team/Team";
import Contact from "./Contact/Contact";
import Story from "./OurStory/Story";
import ServicesTable from "./Services/Services";
import InsightsTable from "./Insights/Insights";
import OthersTable from "./Others/Others";
import MetaverseTable from "./Metaverse/Metaverse";
// import Clients from "./Clients/Clients List/Clients";
import LOGO from '../../../assets/miniLogo.svg'
import WHITELOGO from '../../../assets/miniWhiteLogo.svg'
import AuthContext from "../auth-context";
import ServicesModal from "./Services/Services Modal/ServicesModal";
import ContactsIcon from '@mui/icons-material/Contacts';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import PublicIcon from '@mui/icons-material/Public';
import { BsInfoSquareFill } from 'react-icons/bs'
import Account from "./Account/Account";


const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const AdminPanel = (props) => {
  const theme = useTheme();
  const [open, setOpen] = useState(true);
  const [menuOption, setMenuOption] = useState("logo");
  const authCtx = useContext(AuthContext);

  const overlay = classes.overlay;

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <ServicesModal />
      <AppBar position="fixed" open={open}>
        <Toolbar className={classes.cssssss}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>
          {!open && <Typography variant="h6" noWrap component="div" onClick={() => setMenuOption("logo")}>
            <img src={WHITELOGO} alt='' width='200' />
          </Typography>}
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader className={classes.sidebartop}>
          <Typography variant="h6" noWrap component="div" onClick={() => setMenuOption("logo")}>
            <img src={LOGO} alt='' width='200' className={classes.logooo} />
          </Typography>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon className={classes.arrow} />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List className={classes.sidebartop1}>
          
        <div onClick={() => {
            setMenuOption("account");
          }}
            className={menuOption === "account" ? `${classes.overlay}` : ""}>
            <ListItem
              disablePadding
              sx={{ display: "block" }}
              className={menuOption === "account" ? `${classes.overlay1}` : ""}
            >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  // justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <AccountBoxIcon className={classes.icons} />
                </ListItemIcon>
                <ListItemText
                  primary="Password"
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </ListItem>
          </div>
          <div onClick={() => {
            setMenuOption("services");
          }}
            className={menuOption === "services" ? `${classes.overlay}` : ""}>
            <ListItem
              disablePadding
              sx={{ display: "block" }}
              className={menuOption === "services" ? `${classes.overlay1}` : ""}
            >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  // justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <AssuredWorkloadIcon className={classes.icons} />
                </ListItemIcon>
                <ListItemText
                  primary="Services"
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </ListItem>
          </div>



          <div onClick={() => {
            setMenuOption("team");
          }}
            className={menuOption === "team" ? `${classes.overlay}` : ""}>
            <ListItem
              disablePadding
              sx={{ display: "block" }}
              className={menuOption === "team" ? `${classes.overlay1}` : ""}
            >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  // justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <Groups2Icon className={classes.icons} />
                </ListItemIcon>
                <ListItemText
                  primary="Team"
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </ListItem>
          </div>




          <div onClick={() => {
            setMenuOption("insights");
          }}
            className={menuOption === "insights" ? `${classes.overlay}` : ""}>
            <ListItem
              disablePadding
              sx={{ display: "block" }}
              className={menuOption === "insights" ? `${classes.overlay1}` : ""}
            >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  // justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <InsightsIcon className={classes.icons} />
                </ListItemIcon>
                <ListItemText
                  primary="Insights"
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </ListItem>

          </div>
          
          <div onClick={() => {
            setMenuOption("story");
          }}
            className={menuOption === "story" ? `${classes.overlay}` : ""}>
            <ListItem
              disablePadding
              sx={{ display: "block" }}
              className={menuOption === "story" ? `${classes.overlay1}` : ""}
            >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  // justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <AutoStoriesIcon className={classes.icons} />
                </ListItemIcon>
                <ListItemText
                  primary="OurStory"
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </ListItem>
          </div>

          <div onClick={() => {
            setMenuOption("contact");
          }}
            className={menuOption === "contact" ? `${classes.overlay}` : ""}>
            <ListItem
              disablePadding
              sx={{ display: "block" }}
              className={menuOption === "contact" ? `${classes.overlay1}` : ""}
            >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  // justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <ContactsIcon className={classes.icons} />
                </ListItemIcon>
                <ListItemText
                  primary="Contact"
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </ListItem>
          </div>
          
          <div onClick={() => {
            setMenuOption("metaverse");
          }}
            className={menuOption === "metaverse" ? `${classes.overlay}` : ""}>
            <ListItem
              disablePadding
              sx={{ display: "block" }}
              className={menuOption === "metaverse" ? `${classes.overlay1}` : ""}
            >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  // justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <PublicIcon className={classes.icons} />
                </ListItemIcon>
                <ListItemText
                  primary="Metaverse"
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </ListItem>
          </div>
          
          <div onClick={() => {
            setMenuOption("others");
          }}
            className={menuOption === "others" ? `${classes.overlay}` : ""}>
            <ListItem
              disablePadding
              sx={{ display: "block" }}
              className={menuOption === "others" ? `${classes.overlay1}` : ""}
            >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  // justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <BsInfoSquareFill className={classes.icons} />
                </ListItemIcon>
                <ListItemText
                  primary="Others"
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </ListItem>
          </div>
        </List>
        <Divider />


        <List className={classes.sidebar}>
          <ListItem disablePadding sx={{ display: "block" }} onClick={authCtx.signOut}>
            <ListItemButton
              sx={{
                minHeight: 48,
                // justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <LogoutIcon className={classes.icons} />
              </ListItemIcon>
              <ListItemText primary="Logout" sx={{ opacity: open ? 1 : 0 }} />
            </ListItemButton>
          </ListItem>

        </List>
      </Drawer>

      <Box sx={{ flexGrow: 1 }}>
        {menuOption === "logo" && <div className={classes.test}><img src={LOGO} alt='' width='1000' /></div>}
        {menuOption === "team" && <Team />}
        {menuOption === "insights" && <InsightsTable />}
        {menuOption === "services" && <ServicesTable />}
        {menuOption === "contact" && <Contact />}
        {menuOption === "story" && <Story />}
        {menuOption === "metaverse" && <MetaverseTable />}
        {menuOption === "others" && <OthersTable />}
        {menuOption === "account" && <Account />}

      </Box>
    </Box>
  );
};
export default AdminPanel;
