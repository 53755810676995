export const firebaseConfig = {
    // ask Ali for the apiKey
    // apiKey: "AIzaSyCCJ67QwvE7Crob74FSBue4B34_a-Aj_TM",
    // authDomain: "binsevan.firebaseapp.com",
    // projectId: "binsevan",
    // storageBucket: "binsevan.appspot.com",
    // messagingSenderId: "305264013449",
    // appId: "1:305264013449:web:299ff8ce00ad3b80aad658"
    apiKey: "AIzaSyCZw10nBqDkML82iakmUToKiHHFFqAuxWs",
authDomain: "binsevanwebsite.firebaseapp.com",
projectId: "binsevanwebsite",
storageBucket: "binsevanwebsite.appspot.com",
messagingSenderId: "726457367033",
appId: "1:726457367033:web:32b4ad05be0943febd6528",
measurementId: "G-CBVWC60LS4"
}

