import styled from "styled-components";

export const NavbarContainer = styled.div`
position:  fixed;
display: grid;
width:100%;
height: 5rem;
grid-template-columns: 25% 75%;
background-color:white  ;
z-index: 100;
align-items:center;
box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.33);
`;


export const Navitems = styled.div`
position: relative;
display: flex;
flex-direction: row;
gap: 3vw;
font-family: 'Montserrat';
font-style: normal;
font-weight: 400;
font-size: 10px;
color: #0000;
margin-left: 30%;
width: 100%;
@media screen and (max-width: 1150px) {
margin-left: 20%;
}
/* =========== MEDIA VIEW (SMALL DEVICES) =========== */
@media screen and (max-width: 950px) {
    display: none;
}
`;

export const Navitem = styled.h2`
position:relative;

text-decoration: none;
color: black;
width: fit-content;
margin-left: 0%;
cursor: pointer;
`;

export const Metaverse = styled.h2`
position:relative;

text-decoration: none !important;
margin-left: 0%;
cursor: pointer;
`;

export const NavLogo = styled.div`
position: relative;
margin-left: 8vw;
justify-self: start;
cursor: pointer;


/* =========== MEDIA VIEW (SMALL DEVICES) =========== */
@media screen and (max-width: 600px) {
    margin-left: 2rem;

}
`;

export const MobileIcon = styled.div`
    display: none;

    @media screen and (max-width: 950px){
        display: block;
        position: relative;
        margin-left: 81%;
        width: 10%;
        top: 0;
        right: 0;
        font-size:calc(0.8rem + 2.5vw);
        cursor: pointer;
    }
`