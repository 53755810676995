export const storyobj = {
    id: 'story',
    headline: 'Our Story',
    title1: 'Who Are We',
    description11: 'We are a full-service law firm based in Dubai, United Arab Emirates (UAE) with a global standard of excellence in the provision of legal services. Our understanding of the specificity of the region and commitment to results in all our practice areas are key to the reputation we enjoy today among our clients, our partners, and within the business community in the UAE and internationally.',
    description12:" We have in our service a number of distinguished and experienced advocates and legal consultants who provide practical and valuable legal solutions towards the aimed interests of our clients. In the event that outcomes are not deemed appropriate, our advocates have rights of audience at all levels of UAE Courts and Tribunals.",
    description13:" A leading team of legal assistants and administrative staff provide the necessary backing for the legal team and ensures permanent liaison with our clients. Additionally, our firm is associated with legal offices and business consultancy entities worldwide to grant proper legal representation and service under multiple jurisdictions.",
    description21:'We believe in making ourselves accessible and transparent to clients, employees, partners, and society for a more open world.',
    description22:"Integrity means doing the right thing, no matter what. It's differentiating between right and wrong, staying honest, and delivering consistency. That's why we always keep our promises and never stop fighting fo the truth",
    description23:'Our teammates represent some of the most respected specialist talent in the Arab World. Together we serve UAE, MENA, and Southeast Asia with benchmark-setting results',
    description24:"Deep expertise and a rigorous understanding of our client's markets mean that we can deliver speed, efficiency and excellence in everything that we do.",
    description31: "Durable relationships are the key of our approach towards our clients. Accessibility and transparency, doubled with an in-depth understanding of each particular case, have led the path to our constantly successful results. A tradition of adherence to integrity, knowledge, innovation and client service is a valuable asset that makes Bin Sevan Advocates and Legal Consultants a reputable and lasting partner for its clients. Abiding by the rigors of working both at the regulatory and commercial levels are values to which we have been dedicated. Hence, multiple regional and international organizations appreciate our standardized and complete approach towards their legal matters, at the compliance, corporate and contentious levels.",
    description32:" Along with the constant challenges and increasing needs in the legal field, our negotiation and decision-making experience guarantee up-to-date and customized legal solutions, not only at the diligence level, but for achieving goals and delivering results. Today's business environment requires multilingual and international approaches towards legal matters. We are honored to deliver legal advice to our clients in English, Arabic and other business languages, cross-cultural awareness and globalized approaches being our means for success.",
    description33:"Our foundations are our principles. We are embracing integrity, sound expertise and diversity. We nurture working with our clients for providing legal service to the highest standards.",
    subtitle1:'- Strength Through Transparency',
    subtitle2:'- Integrity is King',
    subtitle3:'- Diversity Where Global Meets Local',
    subtitle4:'- Powered by Knowledge Supercharged by Insight',
   
    //img: require('../../images/assaq_1.png'),
};