import React, { useEffect, useRef, useState } from 'react';
import "./contact.css";
import axios from 'axios';
import Facebook from '../../../assets/facebook-logo.svg'
import Linkedin from '../../../assets/linkedin-logo.svg'
import Instagram from '../../../assets/instagram-logo.svg'
import emailjs from '@emailjs/browser';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import AuthContext from "../../Admin Panel/auth-context";



const ContactUs = () => {
  const authCtx = React.useContext(AuthContext);

  const [email, setEmail] = useState(false);
  const [fname, setFName] = useState("");
  const [lname, setLName] = useState("");
  const [phone, setPhone] = useState("");
  const [formerrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [open, setOpen] = useState(false);
  const [errorrr, setErrorrr] = useState(false);

  const [contactEmail, setcontactEmail] = useState("");
  const [Copyright, setCopyright] = useState("");
  const [contactTitle, setcontactTitle] = useState("");
  const [contactLocation, setcontactLocation] = useState("");
  const [contactPhoneNumber, setcontactPhoneNumber] = useState("");
  const [POBox, setPOBox] = useState("");
  const [contactInstagram, setInstagram] = useState("");
  const [contactFacebook, setFacebook] = useState("");
  const [contactLinkedin, setLinkedin] = useState("");

  useEffect(() => {

    authCtx?.contact.forEach((item) => {

      if (item.title === "Email") {
        setcontactEmail(item.description)
      }
      if (item.title === "Copyright") {
        setCopyright(item.description)
      }
      if (item.title === "Title") {
        setcontactTitle(item.description)
      }
      if (item.title === "Location") {
        setcontactLocation(item.description)
      }
      if (item.title === "Phone Number") {
        setcontactPhoneNumber(item.description)
      }
      if (item.title === "P.O.Box") {
        setPOBox(item.description)
      }
      if (item.title === "Instagram") {
        setInstagram(item.description)
      }
      if (item.title === "Facebook") {
        setFacebook(item.description)
      }
      if (item.title === "Linkedin") {
        setLinkedin(item.description)
      }
    });}, [authCtx.contact ]); 


  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
  const phoneno = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,12}$/im;
  const form = useRef();

  const SendEmailHandler = async (e) => {
    e.preventDefault();
    if (fname && lname && phone && email) {
               console.log("donnnneeee");
    
     setIsSubmit(true);

            emailjs.sendForm('service_3a6kycr', 'template_pr6sglr', form.current, 'Kyh6Lk5sSoyEM0oCw')
               .then((result) => {
                   console.log(result.text);
                   form.current.reset();
                  
                  setOpen(true);

               }, (error) => {
                setErrorrr(true);
                 console.log(error.text);
                 

               });
           } else {
            setFormErrors(Validation(fname,lname,phone,email));
          }
  };
  useEffect(() => {
    console.log(formerrors);
    if(Object.keys(formerrors).length === 0 && isSubmit ) {
      console.log(fname,lname,phone,email)
    }
  },[formerrors])

  const Validation = () => {
    const errors = {};
    
    if (!fname) {
      errors.fname = "*First Name is required!"
    }
    if (!lname) {
      errors.lname = "*Last Name is required!"
    }
    if (!email) {
      errors.email = "*Email is required and must include @!"
    } 
    if (!phone) {
      errors.phone = "*Phone Number is requierd!"
    }
    return errors;
  };
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
    setErrorrr(false);
  };


  return (
    <section>
      <div className='container contact__container'>
        <div className='contact__options'>
          <div className='contact__heading'>
            <p>How Can</p><p style={{marginBottom:'10%'}}> We Help?</p>
          <svg height={20} > 
        <line
          x1="0"
          y1="5"
          x2="calc(10rem + 10vw)"
          y2="5 "
          stroke="rgb(135,28,30)"
          strokeWidth="2"
        />
      </svg>
      </div>

          <div className='contact__descriptions'>
            <h2 className='contact__description'>
            {contactTitle}
          </h2>
          <div className='contact__copyrights'>
            <p>{Copyright}</p>
            <p>{contactEmail}</p>
            <p>{contactPhoneNumber}</p>
            <p>{POBox}</p>
            <a  className='address' href='https://www.google.com/maps/place/Prime+Tower+-+Business+Bay+-+Dubai+-+United+Arab+Emirates/@25.1884122,55.2693277,17z/data=!3m1!4b1!4m6!3m5!1s0x3e5f682d1ff90fe7:0x14375ae978cdf590!8m2!3d25.1884074!4d55.2715164!16s%2Fm%2F03grhtl' target='_blank'>
            {contactLocation}
            </a>
            <div className='contact__option-icons'>
              <a href={contactFacebook} target='_blank'rel="noreferrer" ><img src={Facebook} alt='Facebook-icon' className='contact__icon' /></a>
              <a href={contactLinkedin} target='_blank' rel="noreferrer" ><img src={Linkedin} alt='Linkedin-icon' className='contact__icon' /></a>
              <a href={contactInstagram} target='_blank' rel="noreferrer" ><img src={Instagram} alt='Instagram-icon' className='contact__icon' /></a>
              
              
              
            </div>
          </div>
          </div>
          </div>
          
          <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
          Email Delivered!<br/>Your email was sent successfully.
        </Alert>
      </Snackbar>
      <Snackbar  autoHideDuration={6000} onClose={handleClose}>
      <Alert open={errorrr} onClose={handleClose} severity="error" sx={{ width: '100%' }}>An unexpected error occurred.Email is not sent!<br/>Please try again.</Alert>
      </Snackbar>
         <form ref={form} onSubmit={SendEmailHandler}>
          <div className='contact__name'>
            <div>
          <input
            placeholder="First Name"
            name='first-name'
            onChange={(e) => setFName(e.target.value)}
            className="input__text"
          />
          {fname.length <= 0 ?
           <div className='contact__option-error'>{formerrors.fname}</div>:""}
          </div>
          <div>
          <input
            placeholder="Last Name"
            name='last-name'
            onChange={(e) => setLName(e.target.value)}
            className="input__text"
          />
          {lname.length <= 0 ?
           <div className='contact__option-error'>{formerrors.lname}</div>:""}
          </div>
          </div>
          <input
            onChange={(e) => setEmail(e.target.value)}
            name="email"
            placeholder="Email"
            className='input__text'
          />
          {!regex.test(email)  ?
           <div className='contact__option-error'>{formerrors.email}</div>:""}

          <input
            onChange={(e) => setPhone(e.target.value)}
            placeholder="Phone Number"
            className="input__text"
            name='phone'
          />
          {!phoneno.test(phone)  ?
           <div className='contact__option-error'>{formerrors.phone}</div>:""}

          <textarea
            rows="7"
            placeholder="Message"
            className="input__text"
            name='message'
          />
          <button type='submit' className='contact__btn'>Send</button>
        </form>
      </div>
    </section>
  )
}

export default ContactUs;