import React, { useRef, useState } from 'react'
import classes from './MetaverseModal.module.css';

import addImage from '../../../../../assets/addImage.png';
import TextField from '@mui/material/TextField';
import Card from '../../../Common/Card/Card';
import AuthContext from "../../../auth-context";


const AddMetaverse = (props) => {
  const authCtx = React.useContext(AuthContext);

  const fileInputRef = useRef(null);
  const [id, setid] = useState(authCtx.selectedItem.id);
  const [image, setImage] = useState(authCtx.selectedItem.image);
  const [viewImage, setviewImage] = useState(authCtx.selectedItem.image);

  const [title, setTitle] = useState(authCtx.selectedItem.title);
  const [description, setDescription] = useState(authCtx.selectedItem.description);
  const handleImageChange = (event) => {
    const selectedImage = event.target.files[0];


    const imageUrl = URL.createObjectURL(selectedImage);
    setImage(selectedImage);
    setviewImage(imageUrl);
  };

  const addMetaverseBtn = () => {
    console.log(image);
    if(!authCtx.editmood){
    if (image === '') {
      alert('Please fill all the fields');
      return;
    }
    const data = {
      key: (authCtx.metaverse.length + 1),
      title: title,
      description: description,
    };
    console.log(data);
    authCtx.AddMetaverse(data, image);
    authCtx.setOpenMetaverseModal(false);
  }
  if(authCtx.editmood){
    if (image === '') {
      alert('Please fill all the fields');
      return;
    }
    const data = {
      
      title: title,
      description: description,
    };
    console.log(data);
    authCtx.updateMetaverse(id, data, image);
    authCtx.setOpenMetaverseModal(false);
  }
  };

  return (
    <div>
      <Card className={classes.card1}>
        <div className={classes.heading}><h3> Service Information </h3></div>
        <div className={classes.inputs_location_container}>
          <div className={classes.info_rows}>
            <TextField
              id="title"
              label="Other Title *"
              type="search"
              variant="standard"
              value={title}
              className={classes.input_description}
              onChange={(e) => { setTitle(e.target.value) }}
              disabled
            />
            <TextField
              id="description"
              label="Description *"
              type="search"
              variant="standard"
              multiline
              rows={2}
              value={description}
              className={classes.input_description}
              onChange={(e) => { setDescription(e.target.value) }}
              disabled
            />

          </div>
          <div className={classes.imagee_container}>
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: 'none' }}
            onChange={handleImageChange}
          />
          {!viewImage && <img src={addImage} alt='' height='250'  onClick={() => fileInputRef.current.click()}/>}
            {viewImage && <img src={viewImage} alt='' height='250' onClick={() => fileInputRef.current.click()}/>}
            </div>
        </div>
      </Card>

      <div className={classes.btns}>
        <button className={classes.btncancel} onClick={props.onClose}>
          Cancel
        </button>
        <button className={classes.btnsave} onClick={addMetaverseBtn}>
          Save
        </button>
      </div>
    </div>
  )
}

export default AddMetaverse