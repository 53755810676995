import React from 'react'
import { SidebarContainer, Icon, CloseIcon, SidebarWrapper, SidebarMenu, SidebarLink, Metaverse } from './SidebarElements'

const Sidebar = ({ isOpen, toggle }) => {
    return (
        <SidebarContainer isOpen={isOpen} onClick={toggle}>
            <Icon onClick={toggle}>
                <CloseIcon />
            </Icon>
            <SidebarWrapper>
                <SidebarMenu>
                    <SidebarLink to='home' smooth={true}  duration={500} onClick={toggle}>
                        Home
                    </SidebarLink>
                    <SidebarLink to='story' smooth={true}  duration={500} onClick={toggle}>
                        Our Story
                    </SidebarLink>
                    <SidebarLink to='services' smooth={true}  duration={500} onClick={toggle}>
                        Services
                    </SidebarLink> 
                    <SidebarLink to='team' smooth={true}  duration={500} onClick={toggle}>
                        Team
                    </SidebarLink>
                    <SidebarLink to='insights' smooth={true}  duration={500} onClick={toggle}>
                        Insights
                    </SidebarLink>
                    <Metaverse>
        <a className='permalinks__options' style={{
          textDecoration: 'none',
          color: '#fff',
        }} href='https://app.holofair.net?code=9157254' target='_blank' >Metaverse</a>
        </Metaverse>
                    <SidebarLink to='contact' smooth={true}  duration={500} onClick={toggle}>
                        Contact us
                    </SidebarLink>
                </SidebarMenu>
            </SidebarWrapper>
        </SidebarContainer>
    )
}

export default Sidebar;

