import * as React from 'react';
import Modal from '@mui/material/Modal';
import { Layout, ModalBox, TextContainer, ImageDiv, Heading, Paragraph, Email, Content } from './PopupModalElements';
import './OurTeam.css';

export default function PopupModal(props) {

  return (
<div>
  
        <Modal 
          open={props.open}
          onClose={props.handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Layout>
           <ModalBox >
             <ImageDiv>
                <img src={props.image} alt='ai-image' className='popup-image'/>
                <Heading>{props.Name}</Heading>
                <Content>{props.Content}</Content>
             </ImageDiv>
          <TextContainer>
          
          <Paragraph>{props.description}</Paragraph>
          <Email>E-mail: {props.email}</Email>
          
          </TextContainer>
          </ModalBox>
          </Layout>
      </Modal>
      
    </div>
  );
}