import React, { useContext, useState } from 'react';
import { Button, Form, Input, message } from 'antd';
import AuthContext from '../../auth-context';

import classes from './Account.module.css';
import './styles.css'
import { useForm } from 'antd/es/form/Form';
import { toast } from 'react-toastify';

const Account = () => {
  const authCtx = useContext(AuthContext);
  const [form] = Form.useForm();
  const [passData, setPassData] = useState({
    currentPassword: '',
    newpassword: '',
    confirmpassword: ''
  });
  const [loading, setLoading] = useState(false);

  const handlepassChange = (e) => {
    const { name, value } = e.target;
    setPassData({ ...passData, [name]: value });
  };

  const onFinish = async () => {
    setLoading(true);

    const { currentPassword, newpassword, confirmpassword } = passData;

    // Basic validation
    if (newpassword !== confirmpassword) {
      toast.error('Passwords do not match',{hideProgressBar:true});
      setLoading(false);
      return;
    }

     authCtx.changePassword(currentPassword, newpassword);
      setLoading(false)
      form.resetFields()
  };

  const cancelHandler = () => {
    form.resetFields()
  }

  return (
    <div className={classes.itemsContainer}>
      <div className={classes.itemsHeading}>
        <div className={classes.headContainer}>
          <p className={classes.itemHead}>Account</p>
          <p className={classes.subHead}>Manage your Account Password</p>
        </div>
      </div>
      <div className={classes.form_con}>
        <Form
          layout="vertical"
          name="nest-messages"
          onFinish={onFinish}
          className="form"
          form={form}
        >
            <Form.Item
              name="email"
              label="Email"
              initialValue={authCtx.user.email}
              style={{ border: 'none', borderRadius: '.5em', opacity: '0.5', width:'31%' }}
            >
              <Input
              disabled
                name="email"
                value={authCtx.user?.email}
                placeholder="Email"
                className={classes.inputt}
              />
            </Form.Item>
          <div className={classes.inputsContainer}>
            <Form.Item
              name="currentPassword"
              label="Current Password"
              style={{ border: 'none', borderRadius: '.5em' }}
              rules={[{ required: true, message: 'Please enter your current password' }]}
            >
              <Input.Password
                name="currentPassword"
                type="password"
                placeholder="Current Password"
                className={classes.inputt}
                onChange={handlepassChange}
              />
            </Form.Item>

            <Form.Item
              name="newpassword"
              label="New Password"
              style={{ border: 'none', borderRadius: '.5em' }}
              rules={[
                { required: true, message: 'Please enter a new password' },
                // {
                //     pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@!#$%^&*(){}[\]])[a-zA-Z\d!@#$%^&*(){}[\]]{8,}$/,
                //     message: 'Password must be at least 8 characters long and include at least one lowercase letter, one uppercase letter, one number, and one of the following symbols: ! # $ % ^ & * ( ) { } [ ] .'
                //   }
              ]}
            >
              <Input.Password
                name="newpassword"
                type="password"
                placeholder="New Password"
                className={classes.inputt}
                onChange={handlepassChange}
              />
            </Form.Item>

            <Form.Item
              name="confirmpassword"
              label="Retype New Password"
              style={{ border: 'none', borderRadius: '.5em' }}
              rules={[
                { required: true, message: 'Please confirm your new password' },
                
              ]}
            >
              <Input.Password
                name="confirmpassword"
                type="password"
                placeholder="Retype New Password"
                className={classes.inputt}
                onChange={handlepassChange}
              />
            </Form.Item>
          </div>

          <div className={classes.btnsContainer}>
            <Button size="large" onClick={cancelHandler} className={classes.cancel}>
              Cancel
            </Button>
            <Button
              size="large"
              htmlType="submit"
              className={classes.saveBtn}
              loading={loading}
              style={{ cursor: loading ? 'wait' : 'pointer' }}
            >
              Save
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default Account;
