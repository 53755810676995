import React, { useState } from "react";
import { BlurImg } from "../BlurHash/BlurImage";

import img1 from "../../../assets/Ghazi.webp";
import img2 from "../../../assets/MrFiras.webp";
import img3 from "../../../assets/Madeleine.webp";
import img4 from "../../../assets/Vitaly.webp";
import img5 from "../../../assets/ZEINA_MOUANNES.webp";
import img6 from "../../../assets/img1.webp";
import img7 from "../../../assets/img3.webp";
import img8 from "../../../assets/img4.webp";
import img9 from "../../../assets/SafwanMounzer.webp";
import img10 from "../../../assets/Mrkhodor.webp";
import img11 from "../../../assets/ManalSalman.jpeg";
import imgai1 from "../../../assets/FemaleAvatar.webp";
import imgai2 from "../../../assets/MALE_AVATAR.webp";
import PopupModal from "./TeamPopup";
import AuthContext from "../../Admin Panel/auth-context";


import "./OurTeam.css";

const OurTeam = () => {
  const [AI, setAI] = useState(false);
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const [name, setName] = useState("");
  const [Content, setContent] = useState("");
  const [image, setImage] = useState("");
  const [description, setDescription] = useState("");
  const [email, setEmail] = useState("");
  const authCtx = React.useContext(AuthContext);

  const partnerList = [
    //  {
    //   key: 1,
    //   fullName: "Mr. Firas Alchoufi",
    //   jobTitle: "Founder - Managing  Partner",
    //   imagee: img2,
    //   description:
    //     "Founder and Managing Partner, Firas is the driving force of the firm providing not only his expertise in highly complex, high-value litigation and arbitration cases but also the firm’s vision. Having nearly a decade and a half of legal experience his primary specializations are in real estate and construction dispute resolution matters. His remarkable talent for combining successful legal results with a pragmatic commercial reality makes him an exceptional asset for our clients. Firas speaks both Arabic and English.",
    //   email: "firas.alshoufi@binsevanadvocates.com",
    // },
    // {
    //   key: 2,
    //   fullName: "Ghazi Ibrahim Binsaifan",
    //   jobTitle: "Co-Founder",
    //   imagee: img1,
    //   description:
    //     "Ex–Colonel, worked in Dubai Police for 25 years. After his retirement in 2003, he joined the Bar Association as a registered UAE Attorney before all levels of UAE Courts and Tribunals. Specialist areas: Criminal Law, Employment, Family Law.",
    //   email: "ghazi@binsevanadvocates.com",
    // },
    // {
    //   key: 3,
    //   fullName: "Mr. Raed Kanj",
    //   jobTitle: "Partner - Head of Litigation",
    //   imagee: img7,
    //   description:
    //     "Raed has an extensive experience in complex litigation cases, exposing him to advanced litigation techniques. Raed represents clients in complex criminal, commercial litigation, and bankruptcy, including contract claims, business torts, loan restructuring, lender liability, guaranty disputes, real estate and construction law. Raed uses his litigation skills and knowledge of the litigation process to achieve cost- effective resolutions, in matters that are resolved by simple negotiation all the way to full trials.",
    //   email: "raed.kanj@binsevanadvocates.com",
    // },
    //  {
    //   key: 4,
    //   fullName: "Ms. Zeina Mouannes",
    //   jobTitle: "Partner - Head of Corporate",
    //   imagee: img5,
    //   description:
    //     "Zeina’s expertise is in corporate law and corporate transactions including mergers and acquisitions, joint ventures, and venture capital investments, and has negotiated mergers and acquisitions, an international corporate and business transactions in the Middle East, GCC, and Europe. Zeina is the head of legal at the Lebanese Federation for Sport, Climbing and Mountaineering, an active member of the Gender Equality Committee at the Lebanese Olympic Committee, and is active in a wide variety of community services organizations.",
    //   email: "zeina@binsevanadvocates.com",
    // },
    // {
    //   key: 5,
    //   fullName: "Mr. Vitaly Shipitsin",
    //   jobTitle: "Business Development Manager",
    //   imagee: img4,
    //   description:
    //     "A highly experienced Business Development Manager, Vitaly has more than a quarter of a century’s expertise in business development and expansion. He holds EMBA from the Swedish School of Economics and is fluent in English and Russian.",
    //   email: "vitaly@binsevanadvocates.com",
    // },

    // {
    //   key: 6,
    //   fullName: "Mr. Khodr Ghadban",
    //   jobTitle: "Head of Sport Business Development",
    //   imagee: img10,
    //   description:
    //     "Khodr has a particular focus on sport strategic management, sport promotions and sport organization. Within this course, he covers the fundamentals of the business of sport and provides our clients with the knowledge and skills to manage clubs and sporting organizations in the Middle East and the GCC. Khodr offers specialist insight and examine key strategies and governance models, while using real-world examples from sports businesses and teams. Khodr is a professional mountaineer and an 8000ers summiteer, a former martial art athlete and coach. Khodr is also Founder and President of the Lebanese Federation of Sport Climbing and Mountaineering, Founder and President of DELTA association and a board member of the Alumni of the Technology Faculty at the Lebanese University.",
    //   email: "khodr@binsevanadvocates.com",
    // },
    // {
    //   key: 7,
    //   fullName: "Ms. Madeleine Mendy",
    //   jobTitle: "Partner - Head Of Family Law Department",
    //   imagee: img3,
    //   description:
    //     "Bringing along her vast experience and expertise as a qualified Solicitor (England and Wales) and Legal Consultant in Dubai, Madeleine adds a unique service for our ex-pat clients by heading our Family Law practice and providing General Counsel services to HNW clients, various ex-pat owned companies, and Family Businesses in the UAE. Madeleine represents and advises UAE-based ex-pats on Family Law matters including divorce and child protection through the Dubai, Abu Dhabi, or English Courts. She also provides tailored advice to selected clients in relation to Corporate, Real Estate, Employment, and Wills/Probate matters. Madeleine is a Lecturer at Middlesex University in Dubai.",
    //   email: "madeleine.mendy@binsevanadvocates.com",
    // },
    // {
    //   key: 8,
    //   fullName: "Manal Jihad Salman",
    //   jobTitle: "Partner - sports LAW Department.",
    //   imagee: img11,
    //   description:"Manal has 14 years of experience in Lebanon, Saudi Arabia, and UAE and her main focus of practice is sports law, corporate, banking and commercial sectors. She also acts as mediator and sports arbitrator. In her private life she has honorable sporting achievements. She is a World, Asian, Arab and Lebanese Gold medalist in Thai-boxing sports, as well as, an international referee and instructor. Manal is also a public motivational speaker specializing in sports awareness and spiritual wellbeing.",
    //   email: "Manal@binsevanadvocates.com",
    // },
    // {
    //   key: 9,
    //   fullName: "Mr. Ramadan Nagy",
    //   jobTitle: "Litigation",
    //   imagee: img8,
    //   description:
    //     "Ramadan represents clients in a wide range of litigation. His practice includes criminal, real estate, construction, commercial disputes, family law and legal malpractice matters. Clients value his litigation experience and strategic insight in resolving disputes through trial, appeals and negotiations. In addition, Ramadan litigates a variety of commercial matters and business disputes",
    //   email: "advocate_ramadan@yahoo.com",
    // },
    // {
    //   key: 10,
    //   fullName: "Mr. Ahmed Ghazaly",
    //   jobTitle: "Litigation",
    //   imagee: img6,
    //   description:
    //     "Ahmad has represented a broad range of individuals and entities. His litigation practice ranges from criminal law to rental disputes, real estate, family law and employment matters, Ahmad has extensive experience advising and representing clients and has handled many wide-ranging employment matters for both employees and employers, including employment contracts, separation issues, and labour disputes. He regularly assists, employees and employers with non- compete covenants, benefit issues, shareholder agreements, and severance packages.",
    //   email: "ahmed.ghazaly@binsevanadvocates.com",
    // },
    // {
    //   key: 11,
    //   fullName: "Mr. Safwan Mounzer",
    //   jobTitle: "Litigation",
    //   imagee: img9,
    //   description:
    //     "Safwan is an accomplished litigator having an extensive experience in all phases of litigation and, over the course of his career, has successfully represented a diverse clientele in a broad range of complex family law, labour, real estate litigation, construction and criminal law.",
    //   email: "safwan.munther@binsevanadvocates.com",
    // },
  ];
  const HalDescrition =
    "HAL is an AI lawyer, with a mission to focus on legal sports-related matters. HAL will play a vital role building up the “intelligence” in the Sport Contract Management process as well as creating valuable legal sport content; publishing interesting posts, videos, and graphic that provides information, insight, tips, and more through our firm’s social media platforms.";
  const SevaDescrition =
    "Seva is an AI lawyer, with a mission to create a better client-center experience. Seva is beginning its career learning to provide legal services in the following primary areas; helping automate routine tasks such as legal research and analysis, document management, and billing, litigation review; expertise automation; legal research; contract analytics; contract and litigation document generation.";

  return (
    <>
      <div className="team__container.container">
        <div className="headding-container">
          <div className="team__heading">
            <h1
              className={`headding1 ${!AI && "headding1_active"}`}
              onClick={() => setAI(false)}
            >
              Our Team
            </h1>
          </div>
          <div className="ai__heading">
            <h1
              className={`headding2 ${AI && "headding2_active"}`}
              onClick={() => setAI(true)}
            >
              AI Members
            </h1>
          </div>
        </div>
        {!AI && (
          <div className="cards__container">
            {authCtx?.team.map((member) => {
              return (
                <article
                  className="card__container"
                  onClick={() => {
                    setOpen(true);
                    setName(member.name);
                    setContent(member.jobtitle);
                    setDescription(member.description);
                    setImage(member.image);
                    setEmail(member.email);
                  }}
                >
                  <BlurImg
                    blurhash="LGF5]+Yk^6#M@-5c,1J5@[or[Q6."
                    src={member.image}
                    width={380}
                    height={500}
                    punch={1}
                    className="cards-image"
                  ></BlurImg>
                  <div className="contant-container">
                    <p className="name">{member.name} </p>
                    <p className="description"> {member.jobtitle}</p>
                  </div>

                </article>
              );
            })}
          </div>
        )}
        {AI && (
          <div className="ai__container">
            <div
              className="ai__female__container"
              onClick={() => {
                setOpen(true);
                setName("Seva");
                setContent("AI Lawyer - Legal Assistant");
                setEmail("info@binsevanadvocates.com");
                setDescription(SevaDescrition);
                setImage(imgai1);
              }}
            >
              <img src={imgai1} alt="ai-female" className="ai-image1"></img>
              <p className="ai-text1">
                Seva <br />
                AI Lawyer - Legal <br />
                Assistant
              </p>
            </div>
            <div
              className="ai__male__container"
              onClick={() => {
                setOpen(true);
                setName("HAL");
                setContent("AI Lawyer - Sport Law Legal Assistant");
                setEmail("info@binsevanadvocates.com");
                setDescription(HalDescrition);
                setImage(imgai2);
              }}
            >
              <img src={imgai2} alt="ai-male" className="ai-image2" />
              <p className="ai-text2">
                HAL
                <br /> AI Lawyer - Sport Law <br />
                Legal Assistant
              </p>
            </div>
          </div>
        )}
        <PopupModal
          open={open}
          handleClose={handleClose}
          Name={name}
          Content={Content}
          image={image}
          description={description}
          email={email}
        />
      </div>
      <div id="insights" />
    </>
  );
};

export default OurTeam;
