import styled from "styled-components";

export const StoryContainer = styled.div`
position:relative;
width: 100%;
height: fit-content;
display: grid;
grid-template-columns: 3% 4% 60% 30%;
/* margin-left: 10%; */
/* margin-top: 10%; */
padding-top: 10%;
padding-bottom: 4%;
@media screen and (max-width: 950px) {
  grid-template-columns: 0% 4% 60% 30%;

}

/* =========== MEDIA VIEW (SMALL DEVICES) =========== */
@media screen and (max-width: 820px) {
width: 100%;
display: grid;
grid-template-columns: 0% 13% 80%;
padding: 0% 0% 0% 0%;
margin-left: 0%;
}
`;

export const Numbers = styled.div`
position: relative;
width: 4rem;
display: flex;
flex-direction: column;
margin-top: 130px;
/* margin-left:300px; */
/* margin-right:0%; */
height: 100%;
/* text-align:end; */
/* left: 30%; */

/* =========== MEDIA VIEW (SMALL DEVICES) =========== */
@media screen and (max-width: 820px) {
margin-top: 98px;
font-size: 10px;
margin-left: 20%;
width: 3.4rem;

}
`;
export const Number = styled.button`
position: relative;
border: none;
cursor: pointer;
background-color: transparent;
/* &:focus {
 color: #c3141f;
  } */

  /* =========== MEDIA VIEW (SMALL DEVICES) =========== */
@media screen and (max-width: 820px) {
margin-top: 1px;
font-size: 10px;
}
`;
export const Line1 = styled.div`
  border-left:1px solid #c3141f !important;
  writing-mode: horizontal-tb; 
  height: fit-content;
  font-family: 'Montserrat';
   /* width: fit-content; */
  margin: 10px;
  margin-right: 30px;
  text-align: center;
  writing-mode: vertical-rl;
  color: #c3141f ;
  transform: rotate(180deg);
  padding-top: 1em;
  padding-bottom: 1em;

  `

export const TextWrapper = styled.div`
  position: relative;
  width: 90%;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-left: 2%;
  margin-right: 0%;
  overflow: hidden;


`;
export const TextWrapper1 = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
`;

export const StoryHeading = styled.h1`
  position: relative;
  font-family: 'Superclarendon';
  font-style: normal;
  font-weight: 700;
  font-size: 35px;
  text-align: center;
  color: #171717;

/* =========== MEDIA VIEW (SMALL DEVICES) =========== */
@media screen and (max-width: 820px) {
  font-size: 20px;
}
`;

export const Text = styled.p`
  position: relative;
  width: fit-content;
  font-family: 'Montserrat';
  align-items: center;
  text-align: start;
  width: 100%;
  height:fit-content;
  font-size: 14.6px;

/* =========== MEDIA VIEW (SMALL DEVICES) =========== */
@media screen and (max-width: 820px) {
  justify-content: center;
  font-size: 10px;
  /* text-align: start; */
  margin-bottom: 10%;
}
`;

export const Image = styled.div`
position: relative;
width: 100%;
height: 500px;
/* margin-left: 10px; */
margin-top: 0px;
/* background: var(--color-primary); */

/* =========== MEDIA VIEW (SMALL DEVICES) =========== */
@media screen and (max-width: 820px) {
 display: none;
}
`;

export const Image1 = styled.div`
display: none;

/* =========== MEDIA VIEW (SMALL DEVICES) =========== */
@media screen and (max-width: 820px) {
  display: flex;
  width: 80%;
  margin-left: 10%;
justify-content: center;
margin-top: 0px;
height: 314px;
padding-bottom: 5%;
}
@media screen and (max-width: 600px) {
  width: 100%;
  margin-left: 0%;
padding-bottom: 0%;
}
`;

