import React, { useEffect, useRef, useState } from 'react'
import classes from './TeamModal.module.css';

import TextField from '@mui/material/TextField';
import Card from '../../../Common/Card/Card';
import addImage from '../../../../../assets/addImage.png';
import CreateIcon from "@mui/icons-material/Create";
import AuthContext from "../../../auth-context";

const AddMember = (props) => {  
  const authCtx = React.useContext(AuthContext);
  
  const fileInputRef = useRef(null);
  const [id, setid] = useState(authCtx.selectedItem.id);
  const [image, setImage] = useState(authCtx.selectedItem.image);
  const [viewImage, setviewImage] = useState(authCtx.selectedItem.image);
  const [name, setName] = useState(authCtx.selectedItem.name);
  const [jobtitle, setJobtitle] = useState(authCtx.selectedItem.jobtitle);
  const [description, setDescription] = useState(authCtx.selectedItem.description);
  const [email, setEmail] = useState(authCtx.selectedItem.email);


  

  const handleImageChange = (event) => {
    const selectedImage = event.target.files[0];
    const imageUrl = URL.createObjectURL(selectedImage);
    setImage(selectedImage);
    setviewImage(imageUrl);
  };

  const addMemberBtn = () => {
    console.log(image);
    if(!authCtx.editmood){
    if (image === '') {
      alert('Please fill all the fields');
      return;
    }
    const data = {
      key: (authCtx.team.length + 1),
      name: name,
      jobtitle: jobtitle,
      description: description,
      email: email,

    };
    console.log(data);
    authCtx.addTeam(data, image);
    authCtx.setOpenteamModal(false);
  }
    if(authCtx.editmood){
      if (image === '') {
        alert('Please fill all the fields');
        return;
      }
      const data = {
        id: id,
        name: name,
        jobtitle: jobtitle,
        description: description,
        email: email,
  
      };
      console.log(data);
      authCtx.updateTeam(id, data, image);
      authCtx.setOpenteamModal(false);
    }
  };
  return (
    <div>
      <Card className={classes.card1}>
        <div className={classes.heading}><h3> Member Information </h3></div>
        <div className={classes.inputs_location_container}>

          <div className={classes.info_rows}>
            <TextField
              id="name"
              label="Member Name*"
              type="search"
              variant="standard"
              value={name}
              onChange={(e) => { setName(e.target.value) }}
            // className={classes.input_description}
            />
            <TextField
              id="jobtitle"
              label="Job Title *"
              type="search"
              variant="standard"
              value={jobtitle}
              onChange={(e) => { setJobtitle(e.target.value) }}
            // className={classes.input_description}
            />
            <TextField
              id="email"
              label="Email*"
              type="search"
              variant="standard"
              value={email}
              onChange={(e) => { setEmail(e.target.value) }}
            // className={classes.input_description}
            />
            <TextField
              id="description"
              label="Description"
              type="search"
              multiline
              rows={3}
              variant="standard"
              value={description}
              onChange={(e) => { setDescription(e.target.value) }}
            // className={classes.input_description}
            />
          </div>
          <div className={classes.imagee_container}>
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: 'none' }}
            onChange={handleImageChange}
          />
          {!viewImage && <img src={addImage} alt='' height='250'  onClick={() => fileInputRef.current.click()}/>}
            {viewImage && <img src={viewImage} alt='' height='250' onClick={() => fileInputRef.current.click()}/>}
            </div>
        </div>
      </Card>
      {/* <Card className={classes.card1}> 
          <div className={classes.heading}><h3> Member Image </h3></div>
          <div className={classes.inputs_location_container}>
      <input type="file" onChange={handleImageChange} />
              {image && <img src={image} alt='' width='300'/>}
            </div>
        </Card>  */}

      <div className={classes.btns}>
        <button className={classes.btncancel} onClick={props.onClose}>
          Cancel
        </button>
        <button className={classes.btnsave} onClick={addMemberBtn} >
          Save
        </button>
      </div>
    </div>
  )
}

export default AddMember

