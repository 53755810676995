import React from "react";
import { NavbarContainer, NavLogo, Navitems, Navitem, MobileIcon, Metaverse } from "./NavbarElements";
import BinSevanLogo from '../../../assets/miniLogo.svg'
import { Link } from "react-scroll";
import { FaBars } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const Navbar = ({toggle}) => {
  const navigate = useNavigate();
  return (
    <NavbarContainer>
       <NavLogo>
       <Link className='permalinks__options' to='home' smooth={true}  duration={500} >
        <img src={BinSevanLogo} alt="BinSevanLogo" className="logoo"/>
        </Link>
          </NavLogo>
          <MobileIcon onClick={toggle}>
                        <FaBars />
                    </MobileIcon> 
      <Navitems>
        <Navitem><Link className='permalinks__options' to='story' smooth={true}  duration={500} > Our Story</Link></Navitem>
        {/* <Navitem >Library</Navitem>
        <Navitem> Events </Navitem> */}
        <Navitem>
        <Link className='permalinks__options' to='services' smooth={true}  duration={500} >Services</Link>
         </Navitem>
        <Navitem>
        <Link className='permalinks__options' to='team' smooth={true}  duration={500} >Team</Link>
        </Navitem>
        <Navitem>
        <Link className='permalinks__options' to='insights' smooth={true}  duration={500} >Insights</Link>
        </Navitem>
        <Navitem>
        <Link className='permalinks__options' onClick={()=>{navigate('/metaverse')}}>Metaverse</Link>
        </Navitem>
        {/* <Navitem>Success Stories</Navitem>
        <Navitem>
        <Link className='permalinks__options' to='testimonials' smooth={true}  duration={500} >Testimonials</Link>
        </Navitem> */}
        <Navitem>
        <Link className='permalinks__options' to='contact' smooth={true}  duration={500} >Contact us</Link>
        </Navitem>
      </Navitems>
    </NavbarContainer>
  );
};

export default Navbar;
