import React, { useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "./InsightSwiper.css";
import IMG1 from '../../../assets/Insights-1.webp'
import IMG12 from '../../../assets/Insights-2.webp'
import PopupModal from "./InsightsPopup";
import Aos from "aos";
 import "aos/dist/aos.css"

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import AuthContext from "../../Admin Panel/auth-context";

import { Navigation } from "swiper";
import {
  InsightsContainer,
  Footer,
  Image,
  DescriptionWrapper,
  InsightsHeading,
  SwipperContainer,
  Description,
  Text,
  Date,
  Place,
} from "./InsightsElements";
import { useState } from "react";

const data = [

];

const Insights = () => {

  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const [Title, setTitle] = useState("");
  const [Content, setContent] = useState("");
  // const [Insightimage, setImage] = useState(IMG12);
  const authCtx = React.useContext(AuthContext);

  const [image, setImage] = useState("");
    
  useEffect(() => {
    Aos.init({ duration: 2000})
    console.log("test")
  },[])
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);
  
  const handleSlideChange = (swiper) => {
    setActiveSlideIndex(swiper.activeIndex);
  };

  const constantValue = activeSlideIndex + 1;
  useEffect(() => {

    authCtx?.insights.forEach((item) => {
      
      if (item.key === constantValue) {
        setImage(item.image)
      }
    });}, [constantValue,authCtx.insights ]); 


 
  return (
    <InsightsContainer>
      <Image>
        <img src={image} alt='insightimage' width="100%" height="100%" />
      </Image>
      <DescriptionWrapper>
        <InsightsHeading>Insights</InsightsHeading>
        <SwipperContainer>
          <Swiper navigation={true} modules={[Navigation]} onSlideChange={handleSlideChange} className="mySwiper" >
            {authCtx?.insights.map(({ avatar, title, description, date, location }, index) => {
              return (
                <SwiperSlide className='swiper-slide3'>
                  <Description onClick={() => {
                      setOpen(true);
                      setTitle(title);
                      setContent(description);
                    }} >
                    <Text> {title} </Text>
                    <Footer>
                      <Date> {date} </Date>
                      <Place> {location} </Place>

                    </Footer>
                  </Description>
                </SwiperSlide>
              );
            })}
          </Swiper>
          <PopupModal
            open={open}
            handleClose={handleClose}
            Title={Title}
            Content={Content}
            image={image}
          />
        </SwipperContainer>
      </DescriptionWrapper>
      <div id='contact' />
    </InsightsContainer>
  );
};

export default Insights;
