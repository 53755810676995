import styled from "styled-components";
import BG from '../../../assets/TeamLayout-bg.webp'

export const Layout = styled.div`
position: relative;
/* position: fixed; */
top: 50% !important;
left: 50%;
transform: translate(-50%, -50%);
width:60%;
height: fit-content;
background: transparent;
/* =========== MEDIA VIEW (SMALL DEVICES) =========== */
@media screen and (max-width: 950px) {
    width: 70%;
    height: fit-content;
    /* margin-left: 15%;
    margin-top: 10%; */
    }
/* =========== MEDIA VIEW (SMALL DEVICES) =========== */
@media screen and (max-width: 600px) {
    /* margin-top: 28%; */
    height: fit-content;
    }
`;

export const ModalBox = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  align-items: center;
  display: grid;
  grid-template-columns: 37% 57%;
  background: url(${BG});
  background-size: cover;
  box-shadow: 0px 8px 20px black;
  border-color: transparent;
  /* =========== MEDIA VIEW (SMALL DEVICES) =========== */
  @media screen and (max-width: 600px) {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
`;

export const ImageDiv = styled.div`
  position: relative;
  height: fit-content;
  text-align: center;
  padding-top: 5%;
  padding-bottom: 5%;

  /* =========== MEDIA VIEW (SMALL DEVICES) =========== */
  @media screen and (max-width: 600px) {
    width: 70%;
    height: 40%;
    padding-bottom: 0%;
    left: 0%;
  }
`;

export const TextContainer = styled.div`
  position: relative;
  width: 100%;
  height: fit-content;
  padding-top: 5%;
  padding-bottom: 5%;
  /* =========== MEDIA VIEW (SMALL DEVICES) =========== */
  @media screen and (max-width: 950px) {
    padding-top: 0rem;
  margin-top: 0%;
  }
   /* =========== MEDIA VIEW (SMALL DEVICES) =========== */
   @media screen and (max-width: 600px) {
    padding-top: 0.5rem;
  }
`;

export const Heading = styled.h4`
  position: relative;
  /* margin-left: 2rem; */
  width: 100%;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: calc(0.7rem + 0.6vw);
  letter-spacing: -0.02em;
  text-transform: uppercase;
  color: #fff;
  /* =========== MEDIA VIEW (SMALL DEVICES) =========== */
  @media screen and (max-width: 600px) {
    padding-bottom: 0rem;
  }
`;

export const Content = styled.div`
  position: relative;
  width: 100%;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: calc(0.4rem + 0.6vw);
  /* or 45px */
  letter-spacing: -0.02em;
  text-transform: uppercase;
  color: #fff;
  /* =========== MEDIA VIEW (SMALL DEVICES) =========== */
  @media screen and (max-width: 950px) {
    padding-bottom: 5%;
  }
   /* =========== MEDIA VIEW (SMALL DEVICES) =========== */
   @media screen and (max-width: 600px) {
    padding-bottom: 0%;
  }
`;

export const Paragraph = styled.div`
  position: relative;
  width: 90%;
  margin-left: 2rem;
  text-transform: none;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: calc(0.3rem + 0.6vw);
  line-height: 27px;
  /* or 27px */
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  color: #fff;
  /* =========== MEDIA VIEW (SMALL DEVICES) =========== */
  @media screen and (max-width: 950px) {
    width: 80%;
    line-height: 14px;
    padding-top: 0%;
  }
`;

export const Email = styled.div`
position: relative;
  margin-left: 2rem;
  margin-top: 2rem;
  
  font-size: calc(0.48rem + 0.4vw);
  color: #fff;
   /* =========== MEDIA VIEW (SMALL DEVICES) =========== */
   @media screen and (max-width: 950px) {
  margin-bottom: 1rem;
  margin-top: 1rem;
  }
`;