import React from 'react'
import VIDEO from '../../../assets/Video/Video.webm'
// import SOUND from '../../../assets/Video/welcomeAud.wav'
import './Video.css'

const Video = () => {

  return (
    <>
    <video controls width="100%" autoPlay preload={"true"} playsInline role="presentation" crossOrigin='anonymous'>
      <source src={VIDEO} type="video/mp4" className='video' />
      Sorry, your browser doesn't support embedded videos.
    </video>
    {/* <audio controls autoPlay>
      <source src={SOUND} type="audio/wav" />
    </audio> */}
    </>
  );
};

export default Video;