import React from 'react'
import Classes  from './LogIn.module.css'
import BinSevanLogo from '../../../assets/favLogo.svg'
import googleLogo from '../../../assets/googleLogo.svg'
import MetaverseVideo from '../../../assets/MetaverseVideo.webm'

import {
    GoogleAuthProvider,
    getAuth,
    signInWithPopup,
    signOut,
} from "firebase/auth";
import {
    collection,
    doc,
    getDoc,
    getDocs,
    getFirestore,
    query,
    setDoc,
    addDoc,
    where
} from "firebase/firestore";
import {
    useNavigate
} from 'react-router-dom';

const LogIn = () => {
    const navigate = useNavigate();
    const auth = getAuth();
    const db = getFirestore();
    const googleProvider = new GoogleAuthProvider();

    // const signInWithGoogle = async () => {
    //     try {
    //         const res = await signInWithPopup(auth, googleProvider).then(async (res) => {
    //             console.log(res);
    //             const googleUser = res.user;


    //                 const firstName = googleUser.displayName?.split(' ')[0] || '';
    //                 const lastName = googleUser.displayName?.split(' ')[1] || '';
    //                 const ID = Math.random();
    //                 const newUser = {
    //                     id: googleUser.uid,
    //                     name: firstName +" "+ lastName,
    //                     email: googleUser.email || '',
    //                     date: new Date().toUTCString(),
    //                 };
    //                 addDoc(collection(db, "metaverse"), newUser).then(() => {
    //                     console.log("Document successfully written!");
    //                 });


    //             console.log(newUser);
    //             window.open('https://visit.holofair.io/?type=beta&code=9157254', '_blank');
    //             navigate("/")
    //         });
    //     } catch (error) {
    //         console.log("LogIn Error:",error);
    //     }
    // };
    const entermetaverse = () => {
        window.open('https://visit.holofair.io/?type=beta&code=9157254', '_blank');
        navigate("/")
    }
  return (
    <div className={Classes.logInContainer}>
        <div className={Classes.videoContainer}>
            <video id="video"
          role="presentation"
          preload
          playsInline
          crossOrigin="anonymous"
          loop
          muted
          autoPlay src={MetaverseVideo}  style={{width:'100%',height:'100vh',objectFit:'cover'}}/>
        </div>
        <div className={Classes.contantWrapper}>
            <div className={Classes.contantContainer}>
                <div className={Classes.logoContainer}>
                    <img src={BinSevanLogo} alt="BinSevanLogoo" style={{width:'15%',paddingBottom:'5%'}}  />
                </div>
                <p className={Classes.title}>Welcome to Bin Sevan Metaverse!</p>
                <p className={Classes.description}>
                    Welcome to the Metaverse, a virtual realm where a simple click transports you to a world of virtual meetings and limitless 
                    possibilities. In this digital environment, you can join others in immersive gatherings and collaborate in ways that go 
                    beyond physical limitations. 
                </p>
                <button onClick={entermetaverse} className={Classes.button}>
                {/* <img src={googleLogo} alt="googleLogo" style={{width:'4%',paddingRight:'3%',marginBottom:'-1%'}}  />  */}
                Enter the Metaverse
                    </button> 
            </div>
        </div>
      
    </div>
  )
}

export default LogIn
